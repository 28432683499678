import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment, KfAuthService, KfSharedConstantsService } from '@kf-products-core/kfhub_lib';

export interface ScriptDetails {
    _id?: number;
    name?: string;
    description?: string;
    script?: string;
    isActive?: boolean;
    scriptType?: string;
    inputs?: any;
    outputs?: any;
    constants?: any;
    source?: {
        created: {
            email?: string;
            dateTimestamp?: number;
        },
        lastModified: {
            email?: string;
            dateTimestamp?: number;
        },
    };
}

export interface ScriptData {
    scriptInfo: ScriptDetails;
}

export interface ListData {
    data: string;
}

@Injectable()
export class ScriptService {
    private config = environment().validationApp;
    private readonly clientsPrefix = '/v2admin/clients';
    private readonly actionsPrefix = '/v2admin/actions';
    private options: { headers?: HttpHeaders } = {};

    constructor(private httpClient: HttpClient,
        private authService: KfAuthService,
        private constService: KfSharedConstantsService) {
        const authToken = authService.AuthToken;
        // if (this.config.useAuthHeader && authToken) {
        //     const headers = new HttpHeaders({ authToken });
        //     this.options = { headers };
        // }
    }

    public createScript(details: any): Observable<any> {
        const url = this.config.templateBaseApiUrl + 'scripts';
        console.log('put input', details);
        return this.httpClient.post(url, details, this.options);
    }

    public getScriptInfo(id: number): Observable<ScriptData> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.clientsPrefix + `/${id}`;
        return this.authService.authHttpCall('get', url);
    }

    public getAllScripts(): Observable<ScriptDetails[]> {
        const url = this.config.templateBaseApiUrl + 'scripts';
        const options = {};
        return this.httpClient.get<ScriptDetails[]>(url, this.options);
    }

    public updateScript(details: any): Observable<any> {
        const url = this.config.templateBaseApiUrl + 'scripts';
        console.log('put input', details);
        return this.httpClient.put(url, details, this.options);
    }

    public deleteScript(id: string): Observable<any> {
        const url = this.config.templateBaseApiUrl + 'scripts/' + id;
        console.log('put input', id);
        return this.httpClient.delete(url, this.options);
    }
}
