// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep #footer-region {
  margin-top: 0;
  border-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/login/login.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;AACf","sourcesContent":[":host ::ng-deep #footer-region {\n  margin-top: 0;\n  border-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
