// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host::ng-deep .p-toast .p-toast-message {
  border-radius: 0;
}
:host::ng-deep .p-toast .p-toast-message-content {
  padding: 0 10px;
  justify-content: space-between;
}
:host::ng-deep .p-toast .p-toast-message-content .p-toast-message-content {
  flex-direction: column;
}
:host::ng-deep .p-toast .p-toast-message-content .p-toast-detail {
  margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/notification/notification.component.scss"],"names":[],"mappings":"AAEQ;EACI,gBAAA;AADZ;AAGQ;EACI,eAAA;EACA,8BAAA;AADZ;AAEY;EACI,sBAAA;AAAhB;AAEY;EACI,oBAAA;AAAhB","sourcesContent":[":host::ng-deep{\n    .p-toast{\n        .p-toast-message{\n            border-radius: 0;\n        }\n        .p-toast-message-content{\n            padding: 0 10px;\n            justify-content: space-between;\n            .p-toast-message-content{\n                flex-direction: column;\n            }\n            .p-toast-detail{\n                margin: 0 !important;\n            }\n        }\n    }\n}\n "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
