import { getNormalizedColumnName } from '../utils/utils';

// tslint:disable max-line-length

export const defaultColumns = [
    {
        id: 1,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: false,
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: false,
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'Organization Code',
        displayColumnName: 'Organization Code',
        calcEngineMapping: 'CompanyOrgcode',
        description: 'Please record your Korn Ferry Organization Code here. If you do not have one or remember it, please leave blank.',
        normalizedColumnName: getNormalizedColumnName('Organization Code'),
    },
    {
        id: 2,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: true,
            errorLevel: 'WARNING',
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: false,
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'Executive/Non-Executive Indicator',
        displayColumnName: 'Executive/Non-Executive Indicator',
        calcEngineMapping: 'ExecNonExecIndicator',
        description: 'Please indicate with an "E" or an "N" whether or not this incumbent is an Executive ("E") or Non-Executive ("N"). This designation will allow you to adjust user permissions for reporting.',
        normalizedColumnName: getNormalizedColumnName('Executive/Non-Executive Indicator'),
    },
    {
        id: 3,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: true,
            errorLevel: 'ERROR',
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: false,
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'Job Title',
        displayColumnName: 'Job Title',
        calcEngineMapping: 'JobTitle',
        description: 'Report your organization’s job title for each position supplied.',
        normalizedColumnName: getNormalizedColumnName('Job Title'),
    },
    {
        id: 4,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: true,
            errorLevel: 'WARNING',
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: false,
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'Internal Job Family',
        displayColumnName: 'Internal Job Family',
        calcEngineMapping: 'EmployeeJobFamily',
        description: 'Report your organization’s job family for each position supplied.',
        normalizedColumnName: getNormalizedColumnName('Internal Job Family'),
    },
    {
        id: 5,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: true,
            errorLevel: 'WARNING',
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: false,
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'Your Internal Grade',
        displayColumnName: 'Your Internal Grade',
        calcEngineMapping: 'EmployeeGrade',
        description: 'Report your organization\'s internal grade for each position.',
        normalizedColumnName: getNormalizedColumnName('Your Internal Grade'),
    },
    {
        id: 6,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: true,
            errorLevel: 'WARNING',
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: false,
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'Gender',
        displayColumnName: 'Gender',
        calcEngineMapping: 'Gender',
        description: 'Please indicate the gender to which the incumbent most closely identifies. Use M for Male, F for Female, O for Other/Non-binary, or you have the option to leave this field blank.',
        normalizedColumnName: getNormalizedColumnName('Gender'),
    },
    {
        id: 7,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: true,
            errorLevel: 'WARNING',
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: false,
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'Korn Ferry Hay Job Family/Sub-Function Code',
        displayColumnName: 'Korn Ferry Hay Job Family/Sub-Function Code',
        calcEngineMapping: 'JobSubFamily',
        description: 'Please provide the Korn Ferry Hay Job Family/Sub-Function Code for each position only if you could not match to a specific KFHG Model Job.  You do not need to provide Job Family/Sub-Function Codes for positions for which you provided a KFHG Job Code.',
        normalizedColumnName: getNormalizedColumnName('Korn Ferry Hay Job Family/Sub-Function Code'),
    },
    {
        id: 8,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: true,
            errorLevel: 'WARNING',
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: false,
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'Korn Ferry Hay Reference Level',
        displayColumnName: 'Korn Ferry Hay Reference Level',
        calcEngineMapping: 'ReferenceLevel',
        description: 'Record the two-digit number of the Korn Ferry Hay Reference Level to which each of your organization’s positions match. If an Executive Level Position either use a generic "99" or leave blank.',
        normalizedColumnName: getNormalizedColumnName('Korn Ferry Hay Reference Level'),
    },
    {
        id: 9,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: true,
            errorLevel: 'WARNING',
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: false,
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'Manager Employee ID',
        displayColumnName: 'Manager Employee ID',
        calcEngineMapping: 'ManagerEmployeeID',
        description: 'Report a unique identifier for each employee\'s direct supervisor reported which can be tracked by your organization from one year to the next.',
        normalizedColumnName: getNormalizedColumnName('Manager Employee ID'),
    },
    {
        id: 10,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: true,
            errorLevel: 'WARNING',
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: false,
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'Performance Ranking',
        displayColumnName: 'Performance Ranking',
        calcEngineMapping: 'PerformanceRanking',
        description: 'Please provide the incumbent\'s most recent performance ranking based on the last review cycle. Indicate if the incumbent\'s performance Met expectation (M), was Above expectation (A), or Below expectation (B).',
        normalizedColumnName: getNormalizedColumnName('Performance Ranking'),
    },
    {
        id: 11,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: true,
            errorLevel: 'WARNING',
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: false,
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'Current Level/Job Start Date',
        displayColumnName: 'Current Level/Job Start Date',
        calcEngineMapping: 'JobLevel',
        description: 'Please provide the date the incumbent started their current role.  Please enter dates in MM/DD/YYYY format.',
        normalizedColumnName: getNormalizedColumnName('Current Level/Job Start Date'),
    },
    {
        id: 12,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: true,
            errorLevel: 'WARNING',
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: false,
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'High Potential',
        displayColumnName: 'High Potential',
        calcEngineMapping: 'HighPotential',
        description: 'Please indicate if this incumbent is identified as a high potential employee. Please record high potential employees as "Y" or "Yes".',
        normalizedColumnName: getNormalizedColumnName('High Potential'),
    },
    {
        id: 13,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: true,
            errorLevel: 'WARNING',
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: false,
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'FT/PT Status',
        displayColumnName: 'FT/PT Status',
        calcEngineMapping: 'FullPartTimeStatus',
        description: 'Please indicate if the incumbent is considered a Full-time employee or Part-time employee.  Full-time employees generally work at least 37.5 hours per week. "F"" for Full-time, "P" for Part-time.',
        normalizedColumnName: getNormalizedColumnName('FT/PT Status'),
    },
    {
        id: 14,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: true,
            errorLevel: 'WARNING',
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: false,
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'FTE Percent',
        displayColumnName: 'FTE Percent',
        calcEngineMapping: 'FTEPercent',
        description: 'Full-time employees generally work at least 37.5 hours per week. Please indicate the percent of full-time for each incumbent. (i.e. 1.0 = 37.5 to 40 hours, 0.50 = 20 hours, 0.20 = 8 hours, etc.)',
        normalizedColumnName: getNormalizedColumnName('FTE Percent'),
    },
    {
        id: 15,
        type: {
            validation: 'STRING',
        },
        required: {
            validation: true,
            errorLevel: 'ERROR',
        },
        minValue: {
        },
        maxValue: {
        },
        allowedValues: {
        },
        unique: {
            validation: true,
            errorLevel: 'ERROR',
        },
        groupPresence: {
        },
        dependentColumns: {
        },
        xlsxDisplayColumnName: 'Employee ID',
        displayColumnName: 'Employee ID',
        calcEngineMapping: 'EmployeeID',
        description: 'Record a unique identifier for each incumbent reported which can be tracked by your organization from one year to the next.',
        normalizedColumnName: getNormalizedColumnName('Employee ID'),
    },
];
