
import {forkJoin as observableForkJoin,  Observable ,  Subject } from 'rxjs';

import {map, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { SpinnerVisibilityService } from 'ng-http-loader';
import * as _ from 'lodash';
import * as JSZip from 'jszip';
const zip: JSZip = new JSZip();
import {
    KfAuthService,
    KfSharedConstantsService,
    KfIsearchResultsDataService,
    KfFilterMetadata,
    KfIpagedSearchResults,
    environment,
} from '@kf-products-core/kfhub_lib';

export interface ReportDataEntry {
    name: string;
    type: string;
    imageURL?: string;
    reportURL?: string;
}

@Injectable()
export class QualtricsService {
    private _eventBus: Subject<any>;
    constructor(private http: HttpClient , private authService: KfAuthService,
        private constService: KfSharedConstantsService,public spinner: SpinnerVisibilityService) {
            this._eventBus = new Subject();
         }

    getReportData(country) {
        /*//const base = this.constService.getBaseApiUrl();
        //const url = base + '/v1/actions/payreferencedata';
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','Accept':'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }),
            responseType:'ResponseContentType.Blob'
        };
        //var headers = new HttpHeaders({ 'responseType':  'ResponseContentType.Blob',
                    //'Content-Type':  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet','Accept':'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        const url = "http://localhost:3030/qualtricscollection/surveys/genReport?coutryCode="+country;

        this.authService.authHttpCall('get', url,httpOptions).subscribe(res=> {
          let data = new Blob([res], { type: 'text/plain;charset=utf-8' });
        //FileSaver.saveAs(data, 'text.docx');
        //console.log(blob)
        console.log(data)
      })*/
        const fullUrl = environment().reportURL + country;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', fullUrl, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('authToken', this.authService.AuthToken);

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                console.log('Response');
                const response = xhr.response;
                this.generateFileFromBlob(response);
            }
        };
        xhr.responseType = 'blob';
        xhr.send();
    }

    generateFileFromBlob(blob) {
        console.log('downloadf');
        const fileName = 'Report.xlsx';

        // TODO: IE detection
        let isIE = false;
        const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
        let isMozilla = false;
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            isMozilla = true;
        } else if (match !== -1) {
            isIE = true;
        }
        try {
                if (isMozilla) {
                    const file = new File([blob], fileName, { type: 'application/force-download' });
                    window.open(URL.createObjectURL(file));
                } else {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }

            
            this.broadcast('reportDownloadClicked');
        } catch (e) {}
    }


    public downLoadMultipleFilesParrallel(coutryCodes): Observable<any[]> {

        const markers = [];
        const headers = new HttpHeaders(
            {
                 authtoken: this.authService.AuthToken,
                'Content-Type':'application/json',
            },
        );
        const options = {
            headers,
            responseType:'blob',
        };


        for (let i = 0 ; i < coutryCodes.length ; i++) {
            const fullUrl = environment().reportURL + coutryCodes[i];
            const req = new HttpRequest('GET', fullUrl,options);
            this['response' + i]  = this.http.request(req);
            markers.push(this['response' + i]);
        }
        return observableForkJoin(markers);
    }
    broadcast(key: String, data?: any) {
        this._eventBus.next({ key, data });
    }
    on(key: String): Observable<any> {
        return this._eventBus.asObservable().pipe(
            filter(event => event.key === key),
            map(event => event.data),);
    }
}
