
import {of as observableOf,  Observable } from 'rxjs';

import {delay} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';


export type ApplicationNames = 'DASHBOARD' | 'WORKDAY_COMP' |
                               'PAY_HUB_MANAGEMENT' | 'USER_ADMINISTRATION' | 'VALIDATION_JSON' | 'PAYDATA_REPORT';

export interface Permissions {
    applications: {
        [name in ApplicationNames]?: boolean;
    };
}

export interface PermissionsPayload {
    applications: {
        name: ApplicationNames;
        access: boolean;
    }[];
}

@Injectable()
export class PermissionsService {
    constructor() { }

    public getOwnPermissions(): Observable<Permissions> {
        const ownPermissions = {
            applications: {
                DASHBOARD: true,
                BANNERS: true,
                WORKDAY_COMP: true,
                PAY_HUB_MANAGEMENT: false,
                USER_ADMINISTRATION: false,
                VALIDATION_JSON: true,
                PAYDATA_REPORT: true,
                PDC_VALIDATIONS_T2: true,
                PDC_VALIDATIONS_T3: true,
                SCRIPTS:true,
                KFUTILS: true,
                PAY_REPORTS : true,
                BENEFITS_PREVALENCE:true,
                TALENT_HUB_MANAGEMENT: true,
                EMAIL_MANAGEMENT:true,
                SECTOR_CODING_TOOL:true,
                PDC_T2_FILE_UPLOAD:true,
                QUALTRICS_REPORT: true,
                RIS_REPORTING:true
            },
        };
        return observableOf(ownPermissions).pipe(delay(500));
    }

    public getPermissions(userId: number): Observable<Permissions> {
        return observableOf({
            applications: {
                BANNERS: true,
                DASHBOARD: true,
                WORKDAY_COMP: true,
                PAY_HUB_MANAGEMENT: false,
                USER_ADMINISTRATION: true,
            },
        }).pipe(delay(500));
    }

    public setPermissions(userId: number, permissions: Permissions): Observable<any> {
        const data = this.toServerPayload(permissions);
        console.dir(data);
        return observableOf(true).pipe(delay(500));
    }

    private fromServerPayload(perms: PermissionsPayload): Permissions {
        if (perms == null) {
            return {
                applications: {},
            };
        }
        const applications = _(perms.applications).map(perm => [perm.name, perm.access]).fromPairs().value();
        return { applications };
    }

    private toServerPayload(perms: Permissions): PermissionsPayload {
        const applications = _(perms.applications)
            .toPairs()
            .map(([name, access]: [ApplicationNames, boolean]) => ({ name, access }))
            .value();
        return { applications };
    }
}
