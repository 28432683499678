
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import {
    KfAuthService,
    KfSharedConstantsService,
    KfIsearchResultsDataService,
    KfFilterMetadata,
    KfIpagedSearchResults,
} from '@kf-products-core/kfhub_lib';

export interface ClientDetails {
    clientName?: string;
    sapClientId?: string;
    workdayDetails: {
        tenantURL?: string;
        tenantUserName?: string;
        tenantPassword?: string;
        ratingScale?: 'CARS' | 'BARS'
        isPublished?: boolean;
    };
}

export interface ClientData {
    clientInfo: ClientDetails;
}

export interface ClientsSearchData {
    clientInfo: {
        clientId: number;
        clientName: string;
        sapClientId: string;
    }[];
}

@Injectable()
export class EmailClientService implements KfIsearchResultsDataService {
    private readonly clientsPrefix = '/v1/hrms/paydatacollection/email';
    private readonly actionsPrefix = '/v2admin/actions';

    constructor(private authService: KfAuthService, private constService: KfSharedConstantsService) { }

    public search(
        searchString: string, appliedFilters: KfFilterMetadata[],
        sorting: any[], pageIndex: number, pageSize: number = 20,
    ): Observable<KfIpagedSearchResults & { data: ClientsSearchData }> {        
        let sortBy: string; 
        let sortColumn: string;
        if (sorting.length === 0) {
            sortBy = 'DESC';
            sortColumn = '';
        } else {
            sortBy = sorting[0].sortBy;
            sortColumn = sorting[0].sortColumn;
        }

        let filterBy = '';
        let filterValues = '';
        if (appliedFilters && appliedFilters.length > 0) {
            appliedFilters.forEach((filter: any) => {
                if (filter.values.length > 0) {
                    filterBy = filterBy + filter.id + '|';
                    filterValues = filterValues + filter.values.join(';') + '|';
                }
            });
        } else {
            filterBy = '';
            filterValues = '';
        }

        if(filterBy.length && (filterBy.length===filterBy.lastIndexOf("|")+1)){
            filterBy = filterBy.substr(0,filterBy.length-1);
        }
        if(filterValues.length && (filterValues.length===filterValues.lastIndexOf("|")+1)){
            filterValues = filterValues.substr(0,filterValues.length-1);
        }

        const paramsData = {
            sortColumn,
            sortBy,
            searchString,
            searchColumn:"",
            filterBy,
            filterValues,
            pageIndex: pageIndex.toString(),
            pageSize: pageSize.toString(),
        };

        if (searchString == null || searchString.trim() === '') {
            delete paramsData.searchString;
        }
        const base = this.constService.getBaseApiUrl();
        const url = base + this.clientsPrefix + '/searchClientByAccess?';
        const params = new HttpParams({ fromObject: paramsData });
        return this.authService.authHttpCall('get', url + params.toString());
    }

    public getClientInfo(id: number): Observable<ClientData> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.clientsPrefix + `/${id}`;
        return this.authService.authHttpCall('get', url);
    }

    public updateClient(data: _.PartialDeep<ClientData>): Observable<any> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.clientsPrefix;
        return this.authService.authHttpCall('put', url, data);
    }

    public createClient(details: ClientDetails): Observable<any> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.clientsPrefix;
        const clientInfo: any = _.cloneDeep(details);
        clientInfo.ClientTypeID = 'WORKDAY_CLIENT';
        return this.authService.authHttpCall('post', url, { clientInfo }).pipe(map((d = {}) => d.clientID));
    }

    public publishCompetencies(clientId: number): Observable<any> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.actionsPrefix + `/publishcompetencies?clientId=${clientId}`;
        return this.authService.authHttpCall('post', url);
    }
}
