import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment, KfSharedConstantsService, KfAuthService, KfIsearchResultsDataService, KfFilterMetadata, KfIpagedSearchResults, KfSpinnerService } from '@kf-products-core/kfhub_lib';
import { SpinnerVisibilityService } from 'ng-http-loader';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';


@Injectable()
export class TalentHubManagementService {

    public config = environment().validationApp;
    private baseUrl = environment().baseApiUrl;
    loggedInClientId: any;
    userId: any;
    thmBaseUrl: string;

    constructor(
        private authService: KfAuthService,
        private http: HttpClient,
        private constService: KfSharedConstantsService,
        public spinner: SpinnerVisibilityService,
        private spinnerService: KfSpinnerService) {
        this.loggedInClientId = this.authService.getSessionInfo().User.ClientId;
        this.userId = this.authService.getSessionInfo().User.UserId;
        this.baseUrl = this.constService.getBaseApiUrl();
        this.thmBaseUrl = this.baseUrl + '/v1/hrms/thportal/admin/';
    }

    // tslint:disable-next-line: max-line-length
    public search(searchString: string, appliedFilters: KfFilterMetadata[], sorting: any[], pageIndex: number = 0, pageSize: number = 20) {
        this.spinner.show();
        const url = this.thmBaseUrl + 'clients?';
        const paramsData = {
            loggedInUserClientId: this.loggedInClientId,
            userId: this.userId,
            locale: 'en',
            pageIndex: pageIndex.toString(),
            pageSize: pageSize.toString(),
            searchString,
            // searchColumn: '',
            // sortBy: '',
            // sortColumn: '',
        };
        if (searchString == null || searchString.trim() === '') {
            delete paramsData.searchString;
        }
        const params = new HttpParams({ fromObject: paramsData });
        this.spinner.hide();
        return this.authService.authHttpCall('get', url + params.toString());
    }

    uploadUrl() {
        const selectedClientInfo = JSON.parse(sessionStorage.getItem('selected-client'));
        const pamsId = Number(selectedClientInfo.PamsID);
        const url = `${this.thmBaseUrl}uploadurl?loggedInUserClientId=${this.loggedInClientId}&uploadClientId=${pamsId}&userId=${this.userId}&locale=en`;
        return this.authService.authHttpCall('get', url);
    }

    public uploadFileAWSS3(fileUploadURL, contentType, file): Observable<any> {
        const config = {
            headers: { 'Content-Type': contentType },
        };
        return this.http.put(fileUploadURL, file, config);
    }

    public getUploadStatus(fileUUID: string): Observable<any> {
        // tslint:disable-next-line: max-line-length
        const url = this.thmBaseUrl + 'uploadstatus?fileUUID=' + fileUUID; // + '&loggedInUserClientId=' + this.loggedInClientId + '&userId=' + this.userId + '&locale=en';
        return this.authService.authHttpCall('get', url);
    }

    public downloadFileWithStatus(fileUUID: string, status: number, clientId?: number): Observable<any> {
        let url;
        if (fileUUID.length === 0) {
            if(clientId) {
                url = this.thmBaseUrl + 'downloadurl?&status=' + status + '&clientId=' + clientId;
            } else {
            url = this.thmBaseUrl + 'downloadurl?&status=' + status;
            }
        } else {
            url = this.thmBaseUrl + 'downloadurl?fileUUID=' + fileUUID + '&status=' + status;
        }
        return this.authService.authHttpCall('get', url);
    }

    public pushData(data): Observable<any> {
        const url = this.thmBaseUrl + 'pushdata';
        return this.authService.authHttpCall('POST', url, data);
    }
    // ----WORKDAY----
    public initiateWorkdayDownloadFile(): Observable<any> {
        const headers = new HttpHeaders(
            { loggedInUserClientId: this.loggedInClientId },
        );
        const options = {
            headers,
        };
        const selectedClientInfo = JSON.parse(sessionStorage.getItem('selected-client'));
        const pamsId = Number(selectedClientInfo.PamsID);

        const url = this.baseUrl + '/v1/hrms/thportal/workday/downloadurl?clientId=' + pamsId;

        // return this.authService.authHttpCall('GET', url, null, options, null, options);
        return this.authService.authHttpCallv2('GET', url, null, { requestType: 'hidden' });
    }

    public servelLevelReportDownloadFile() {
        const url = this.baseUrl + '/v1/hrms/thportal/admin/talenthub/statistics/serverstatistics';
        return url;
    }

    public clientLevelReportDownloadFile() {
        const selectedClientInfo = JSON.parse(sessionStorage.getItem('selected-client'));
        const pamsId = Number(selectedClientInfo.PamsID);
        const url = this.baseUrl + '/v1/hrms/thportal/admin/talenthub/statistics/clientstatistics/?requestClient=' + pamsId;
        return url;
    }

    public checkWorkdayUploadDownloadStatus(fileUUID: string): Observable<any> {
        const selectedClientInfo = JSON.parse(sessionStorage.getItem('selected-client'));
        const pamsId = Number(selectedClientInfo.PamsID);
        const headers = new HttpHeaders(
            { loggedInUserClientId: this.loggedInClientId},
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + '/v1/hrms/thportal/workday/status?fileUUID=' + fileUUID;
        // return this.authService.authHttpCall('GET', url, null, options);
        return this.authService.authHttpCallv2('GET', url, null, { requestType: 'hidden'});

    }
    public initiateWorkdayUploadFile(): Observable<any> {
        const headers = new HttpHeaders(
            { loggedInUserClientId: this.loggedInClientId },
        );
        const options = {
            headers,
        };
        const selectedClientInfo = JSON.parse(sessionStorage.getItem('selected-client'));
        const pamsId = Number(selectedClientInfo.PamsID);
        const url = this.baseUrl + '/v1/hrms/thportal/workday/uploadurl?clientId=' + pamsId;
        return this.authService.authHttpCallv2('GET', url, null, { requestType: 'hidden' });

    }
    public pushDataWorkday(data): Observable<any> {
        const url = this.baseUrl + '/v1/hrms/thportal/workday/pushdata';
        return this.authService.authHttpCall('POST', url, data);
    }
}
