
import {map, filter} from 'rxjs/operators';
import { Observable, Observer ,  Subject } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
    KfAuthService,
    KfSharedConstantsService,
    environment,
  } from '@kf-products-core/kfhub_lib';

export interface FileInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}

export interface UploadResult {
    arrayBuffer: ArrayBuffer;
    file: File;
}

@Injectable()
export class BlobService {
    public reports = '/v1/hrms/reports/';

    public baseApiUrl = environment().baseApiUrl;

    private uploadInput: HTMLInputElement = null;
    private _eventBus: Subject<any>;
    constructor(@Inject(DOCUMENT) private document: Document,private authService: KfAuthService) {
        this._eventBus = new Subject();
    }

    private click(element: HTMLElement) {
        if (this.document.createEvent) {
            const event = this.document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            element.dispatchEvent(event);
        } else {
            element.click();
        }
    }

    public create(body: any, type: string): Blob {
        return new Blob([body], {
            type,
        });
    }

    public createXLSX(body: any): Blob {
        return this.create(body, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    }

    public createJSON(body: any): Blob {
        return this.create(body, 'application/json');
    }

    public upload(): Observable<UploadResult> {
        this.uploadInput = null;
        return Observable.create((observer: Observer<UploadResult>) => {
            if (!this.uploadInput) {
                const handleFileSelect = (event: FileInputEvent) => {
                    const files = event.target.files;
                    if (files && files.length) {
                        const file = files[0];
                        const fileReader = new FileReader();
                        fileReader.onloadend = () => {
                            observer.next({
                                arrayBuffer: fileReader.result as ArrayBuffer,
                                file,
                            });
                            observer.complete();
                        };
                        fileReader.readAsArrayBuffer(file);
                    }
                };
                this.uploadInput = this.document.createElement('input');
                this.uploadInput.type = 'file';
                this.uploadInput.style.display = 'none';
                this.uploadInput.addEventListener('change', handleFileSelect, false);
            }
            this.click(this.uploadInput);
        });
    }

    public download(blob: Blob, fileName: string): void {
        const downloadLink = this.document.createElement('a');
        const nav = (window.navigator as any);
        if (nav.msSaveOrOpenBlob) {
            downloadLink.onclick = () => {
                nav.msSaveOrOpenBlob(blob, fileName);
            };
            this.click(downloadLink);
        } else {
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.setAttribute('download', fileName);
            this.click(downloadLink);
            window.URL.revokeObjectURL(downloadLink.href);
        }
        this.broadcast('downloadLinkClicked');
    }
    public downloadFromText(content, fileName, contentType) {
        var a = document.createElement("a");
        var file = new Blob([content], {type: contentType});
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }

    public downloadFromURL(url:string){
        window.open(url, '_blank');
    }

    public downloadFromURLSamePage(url) {
        window.location.href = url;
    }

    downloadReportFile(downloadId, body, name) {
        const serverUrl =
        this.baseApiUrl +
        this.reports + 'downloadReports?reportId='+downloadId;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', serverUrl, true);
      // xhr.setRequestHeader('Content-Type', 'text/csv'); // application/json
        xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        xhr.setRequestHeader('authToken', this.authService.AuthToken);
        xhr.onreadystatechange = () => {
            // if (xhr.readyState === XMLHttpRequest.DONE) {
            //     const response = xhr.response;
            //     this.generateFileFromBlob(response,name);
            // }
            if (xhr.readyState === 4 && xhr.status === 200) {
                const response = xhr.response;
                this.generateFileFromBlob(response, name);
            }
            if (xhr.readyState === 4 && xhr.status !== 200) {
                this.broadcast('downloadLinkClicked', 'error');
            }
            if (xhr.readyState === 4 && xhr.status === 0) {
                this.broadcast('downloadLinkClicked', 'error');
            }
        };
        xhr.responseType = 'blob';
        xhr.send(JSON.stringify(body));
    }

    generateFileFromBlob(blob , name) {
        const fileName = name + '.xlsx';
      // TODO: IE detection
        let isIE = false;
        const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/);
        let isMozilla = false;
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            isMozilla = true;
        } else if (match !== -1) {
            isIE = true;
        }
        try {
            if (isMozilla) {
                    const file = new File([blob], fileName, { type: 'application/force-download' });
                    window.open(URL.createObjectURL(file));
                } else {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }
            this.broadcast('downloadLinkClicked');
        } catch (e) {}
    }
    broadcast(key: String, data?: any) {
        this._eventBus.next({ key, data });
    }
    on(key: String): Observable<any> {
        return this._eventBus.asObservable().pipe(
            filter(event => event.key === key),
            map(event => event.data),);
    }

}
