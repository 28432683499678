import { NgModule, ModuleWithProviders } from '@angular/core';
import { ClientsService } from './clients.service';
import { ScriptService } from './scripts.service';
import { MessageService } from './message.service';
import { PayhubService } from './payhub.service';
import { PayDataService } from './paydata.service';
import { QualtricsService } from './qualtrics.service';
import { QualtricsSurveyService } from './qualtrics-survey.service';
import { FileUploadService } from './file-upload.service';
import { ReportDataUploaderService } from './report-data-uploader.service';
import { UsersService } from './users.service';
import { UsersMetadataService } from './users-metadata.service';
import { FilterMetadataConverterService } from './filter-metadata-converter.service';
import { PermissionsService } from './permissions.service';
import { ValidationTemplateService } from './validation-template.service';
import { PostProcessingService } from './post-processing-t2.service';
import { PostProcessingT3Service } from './post-processing-t3.service';
import { S3FileService } from './s3-file.service';
import { BlobService } from './blob.service';
import { PayReportingService } from './pay-reporting.service';
import { TalentHubManagementService } from './talent-hub-management.service';
import { KFAManagementService } from './kfa-management.service';
import { TalentHubManagementSPService } from './talent-hub-management-sp.service';
import {EmailService} from './email.service'
import {EmailClientService} from './email.client.service'
import { EmailAllClientService } from './email-clients-group.service'; 
import { RisReportingService } from './ris-reporting-service';
import { UserPermissionsService } from './user-permissions.service';
import { CompanyDemographicsService } from './company-demographic.service';
import { PeerGroupService } from './peer-group.service';
import { CustomGradeService } from './custom-grade.service';

@NgModule({
    imports: [],
    declarations: [],
})
export class SharedServicesModule {
    static forRoot(): ModuleWithProviders<SharedServicesModule> {
        return {
            ngModule: SharedServicesModule,
            providers: [
                ClientsService,
                ScriptService,
                MessageService,
                PayhubService,
                PostProcessingService,
                PostProcessingT3Service,
                PayDataService,
                QualtricsService,
                QualtricsSurveyService,
                FileUploadService,
                ReportDataUploaderService,
                UsersService,
                UsersMetadataService,
                PermissionsService,
                FilterMetadataConverterService,
                ValidationTemplateService,
                S3FileService,
                BlobService,
                PayReportingService,
                TalentHubManagementService,
                TalentHubManagementSPService,
                EmailService,
                EmailClientService,
                EmailAllClientService,
                RisReportingService,
                KFAManagementService,
                UserPermissionsService,
                CompanyDemographicsService,
                PeerGroupService,
                CustomGradeService

            ],
        };
    }
}
