import { concatMap, filter } from "rxjs/operators";
import {
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ElementRef,
} from "@angular/core";
import * as _ from "lodash";
import {
    Router,
    Event,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
} from "@angular/router";
import {
    KfAuthService,
    KfSharedConstantsService,
    KfIdleService,
    environment,
} from "@kf-products-core/kfhub_lib";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ConfirmationService } from "primeng/api";
import { PayReportingService } from "./shared-services/pay-reporting.service";
import { Title } from "@angular/platform-browser";
import { KfSpinnerService } from "@kf-products-core/kfhub_lib";
import { KfLoadingControllerService } from "@kf-products-core/kfhub_lib";
declare var jQuery: any;
@UntilDestroy()
@Component({
    selector: "kf-app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy, OnInit {
    loading = false;
    public showNavigation = false;
    public isBlocked = false;
    public idleWarningTimeEnd;
    idleState = "Not started.";
    timedOut = false;
    lastPing?: Date = null;
    acceptLabel = "Continue";
    rejectLabel = "Cancel";
    private config = environment();
    tokenExpiresIn;
    interval: any;
    showModal = false;
    constructor(
        private router: Router,
        public idleService: KfIdleService,
        public authService: KfAuthService,
        public constService: KfSharedConstantsService,
        public confirmationService: ConfirmationService,
        private _payReportingService: PayReportingService,
        private titleService: Title,
        public spinnerService: KfSpinnerService,
        public loadingService: KfLoadingControllerService
    ) {
        this.router.events.subscribe((event: Event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.loading = true;
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    this.loading = false;
                    break;
                }
                default: {
                    break;
                }
            }
        });
        this._payReportingService
            .on("showConfirmDialog")
            .subscribe((showConfirmDialog) => {
                this.getCountriesData();
            });
        this._payReportingService
            .on("showNgIdleConfirmDialog")
            .subscribe((showNgIdleConfirmDialog) => {
                this.logout();
            });
    }

    ngOnInit() {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .pipe(untilDestroyed(this))
            .subscribe(
                (event: NavigationEnd) =>
                    (this.showNavigation = !_.includes(event.url, "/login")),
                   
                
            );
        this.idleService.IdleStartThreshold = this.config.idleStartThreshold;
        this.idleService.TimeoutCountdownThreshold =
            this.config.timeoutCountdownThreshold;
        this.idleService.OnTimeoutWarning.subscribe((countdown: number) =>
            this.onIdleTimeoutWarning(countdown)
        );
        this.idleService.OnTimeout.subscribe(() => {
            this.onIdleTimeout();
        });
        this.idleService.OnIdleEnd.subscribe(() => {
            this.onIdleEnd();
        });
        // this.idleService.startIdleWatch();

        if (this.authService.isAuthenticated()) {
            console.log("started idle service");

            this.idleService.startIdleWatch();
        }
        if (this.config.region && this.config.region == "US") {
            this.titleService.setTitle("Talent Hub Portal - US");
        } else if (this.config.region && this.config.region == "EU") {
            this.titleService.setTitle("Talent Hub Portal - EU");
        } else if (this.config.region && this.config.region == "CN") {
            this.titleService.setTitle("Talent Hub Portal - CN");
        }
    }

    onIdleTimeoutWarning(countdown: number) {
        this.idleWarningTimeEnd = countdown;
        console.log("idle timeout warning!");
        // this.confirm();
    }
    // confirm() {
    //     this.acceptLabel = 'Yes';
    //     this.rejectLabel = 'No';
    //     this.confirmationService.confirm({
    //         message: 'Your session is about to expire in ' + this.idleWarningTimeEnd + ' seconds. Do you want to continue?',
    //         accept: () => {
    //             // Actual logic to perform a confirmation
    //             this.getCountriesData();
    //             this.reset();
    //         },
    //         reject: () => {
    //             console.log('cancelled');
    //             this.logout();

    //         },
    //     });
    // }
    onConfirmDialog() {
        this.getCountriesData();
        this.showModal = false;
    }
    onCancelDialog() {
        this.logout();
        this.showModal = false;
    }
    public getCountriesData() {
        this._payReportingService.getCountrieBasedOnModule("QUERIES").subscribe(
            (response) => {
                if (response && response["status"] === 200) {
                    console.log("session retained from app");
                }
            },
            (error) => {
                console.log("Countries list", error);
            }
        );
    }
    onIdleTimeout() {
        jQuery(".ui-confirmdialog").hide();
        jQuery(".ui-dialog-mask").hide();
        this.logout();
    }
    onIdleEnd() {}

    reset() {
        this.idleService.resetIdleWatch();
    }

    block() {
        this.isBlocked = true;
    }

    unblock() {
        this.isBlocked = false;
    }

    ngOnDestroy() {}

    public logout() {
        this.authService
            .removeSessionInfo()
            .subscribe(() => this.router.navigate(["login"]));
    }
}
