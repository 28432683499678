
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import {
    KfAuthService,
    KfSharedConstantsService,
    KfIsearchResultsDataService,
    KfFilterMetadata,
    KfIpagedSearchResults,
} from '@kf-products-core/kfhub_lib';

export interface ClientDetails {
    clientName?: string;
    sapClientId?: string;
    workdayDetails: {
        tenantURL?: string;
        tenantUserName?: string;
        tenantPassword?: string;
        ratingScale?: 'CARS' | 'BARS'
        isPublished?: boolean;
        publishedDate?: any;
    };
}

export interface ClientData {
    clientInfo: ClientDetails;
}

export interface ClientsSearchData {
    clientInfo: {
        clientId: number;
        clientName: string;
        sapClientId: string;
    }[];
}

@Injectable()
export class ClientsService implements KfIsearchResultsDataService {
    private readonly clientsPrefix = '/v2admin/clients';
    private readonly actionsPrefix = '/v2admin/actions';

    constructor(private authService: KfAuthService, private constService: KfSharedConstantsService) { }

    public search(
        searchString: string, appliedFilters: KfFilterMetadata[],
        sorting: any[], pageIndex: number, pageSize: number = 20,
    ): Observable<KfIpagedSearchResults & { data: ClientsSearchData }> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.clientsPrefix + '/searchclient?';
        const paramsData = {
            pageIndex: pageIndex.toString(),
            pageSize: pageSize.toString(),
            searchString,
            clientType: 'WORKDAY_CLIENT',
            searchColumn: 'NAME',
            sortBy: 'NAME',
        };
        if (searchString == null || searchString.trim() === '') {
            delete paramsData.searchString;
        }
        const params = new HttpParams({ fromObject: paramsData });
        return this.authService.authHttpCall('get', url + params.toString());
    }

    public getClientInfo(id: number): Observable<ClientData> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.clientsPrefix + `/${id}`;
        return this.authService.authHttpCall('get', url);
    }

    public updateClient(data: _.PartialDeep<ClientData>): Observable<any> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.clientsPrefix;
        return this.authService.authHttpCall('put', url, data);
    }

    public createClient(details: ClientDetails): Observable<any> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.clientsPrefix;
        const clientInfo: any = _.cloneDeep(details);
        clientInfo.ClientTypeID = 'WORKDAY_CLIENT';
        return this.authService.authHttpCall('post', url, { clientInfo }).pipe(map((d = {}) => d.clientID));
    }

    public publishCompetencies(clientId: number): Observable<any> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.actionsPrefix + `/publishcompetencies?clientId=${clientId}`;
        return this.authService.authHttpCall('post', url);
    }
}
