
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import {
    KfAuthService,
    KfSharedConstantsService,
    KfIsearchResultsDataService,
    KfFilterMetadata,
    KfIpagedSearchResults,
} from '@kf-products-core/kfhub_lib';

export interface ReportDataEntry {
    name: string;
    type: string;
    imageURL?: string;
    reportURL?: string;
}

@Injectable()
export class PayhubService {

    constructor(private authService: KfAuthService, private constService: KfSharedConstantsService) { }

    getPayReferenceData(): Observable<any> {
        const base = this.constService.getBaseApiUrl();
        const url = base + '/v1/actions/payreferencedata';
        return this.authService.authHttpCall('get', url);
    }

    getReportData(clientId: string, coutryCode: string): Observable<ReportDataEntry[]> {
        const base = this.constService.getBaseApiUrl();
        const url = base + `/v1/companies/payanalytics?clientId=${clientId}`;
        return this.authService.authHttpCall('get', url).pipe(map((data) => {
            if (data == null) {
                return [];
            }
            const countryReports = _.find(data.countries, ['code', coutryCode]);
            if (countryReports == null) {
                return [];
            }
            const reportData = _.map(countryReports.analyticsData, d => ({
                name: d.name,
                type: d.type,
                imageURL: _.get(d, 'image.imageURL'),
                reportURL: _.get(d, 'report.reportURL'),
            }));
            return reportData;
        }));
    }
}
