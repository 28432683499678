import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, KfAuthService, KfSharedConstantsService, KfSpinnerService } from '@kf-products-core/kfhub_lib';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { Observable, Subject, of as observableOf } from 'rxjs';
import { filter, map } from 'rxjs/operators';



@Injectable()
export class PeerGroupService {
  public config = environment().validationApp;
  private baseApiUrl = environment().baseApiUrl;
  public postprocessing = '/v1/hrms/postprocessing/';
  public reports = '/v1/hrms/reports/';
  private _eventBus: Subject<any>;
  constructor(
    private authService: KfAuthService,
    private http: HttpClient,
    private constService: KfSharedConstantsService,
    public spinner: SpinnerVisibilityService,
    private spinnerService: KfSpinnerService) {
    this._eventBus = new Subject();

  }

  broadcast(key: String, data?: any) {
    this._eventBus.next({
      key, data,
    });
  }
  // the listener method
  on(key: String): Observable<any> {
    return this._eventBus.asObservable().pipe(
      filter(event => event.key === key),
      map(event => event.data));
  }


  public getPeerGroupsNew(apiPath: string): Observable<any> {

    const url = this.baseApiUrl + this.postprocessing + apiPath;
    return this.authService
      .authHttpCall('GET', url, null, null, true);
  }
  public getPeerGroups(apiRelPath: string): Observable<any> {
    const serverUrl =
      this.baseApiUrl +
      this.postprocessing + apiRelPath;
    return this.authService
      .authHttpCall('GET', serverUrl, null, null, true);
  }

  public viewPeerGroup(peerGroupId) {
    const serverUrl =
      this.baseApiUrl +
      this.postprocessing +
      'peergroups/' + peerGroupId;
    return this.authService
      .authHttpCall('GET', serverUrl, null, null, true);
  }

  public deletePeerGroupSet(peerGroupId) {
    const serverUrl =
      this.baseApiUrl +
      this.postprocessing +
      'peergroups/' + peerGroupId;

    return this.authService
      .authHttpCall('DELETE', serverUrl, null, null, true);
  }

}