
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    environment,
    KfAuthService,
    KfSharedConstantsService,
    KfSpinnerService,
    KfStorageService
} from '@kf-products-core/kfhub_lib';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from './shared-services/message.service';
import * as _ from 'lodash';
import { PayReportingService } from './shared-services/pay-reporting.service';
import { catchError } from 'rxjs/operators';
import { zip } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private authExpiredText: string;
    private errorText: string;
    private okText: string;
    private baseUrl = environment().baseApiUrl;
    private tokenExpiresIn;
    private interval: any;
    private flag: boolean = true;

    constructor(
        private kfAuth: KfAuthService,
        private constService: KfSharedConstantsService,
        private translate: TranslateService,
        private router: Router,
        private messageService: MessageService,
        private spinnerService: KfSpinnerService,
        private storageService: KfStorageService,
        private _payReportingService: PayReportingService,
    ) {
        zip([
            this.translate.get('authExpiredText'),
            this.translate.get('error'),
            this.translate.get('ok'),
        ]).subscribe(([authExpiredText, errorText, okText]) => {
            this.authExpiredText = authExpiredText;
            this.errorText = errorText;
            this.okText = okText;
        });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const validAPI = req.url.includes(this.baseUrl);
        const isLogout = req.url.includes('/logout');
        if (isLogout) {
            this.flag = true;
        }
        if (validAPI && !isLogout) {
            const currentDate = new Date();
            const twentyMinutesLater = new Date(currentDate.getTime() + (19 * 60 * 1000)); // 20 minues
            this.storageService.setItem('tokenExpiresIn', twentyMinutesLater.getTime().toString());
            this.storageService.setItem('ng2Idle.main.expiry', twentyMinutesLater.getTime().toString());
            this.tokenExpiresIn = twentyMinutesLater.getTime().toString();
            // console.log('API Cll', this.baseUrl, twentyMinutesLater.getTime());
            if (this.flag) {
                this.checkTokenExpiry();
            }
        }

        const errorCatcher = (e: any) => {
            if (e.status === 401) {
                localStorage.clear();
                this.handleAuthError(e);
            } else {
                this.showError(e);
                throw e;
            }
            return observableOf(null);
        };
        if (this.isProtectedApiCall(req)) {

            if (this.kfAuth.isAuthenticated()) {
                return next.handle(req).pipe(catchError(errorCatcher));
            } else {
                this.handleAuthError('');
                return observableOf(null);
            }
        } else {
            return next.handle(req).pipe(catchError(errorCatcher));
        }
    }

    private handleAuthError(err) {
        this.router.navigate(['login']);
        setTimeout(
            () => {
                if (this.spinnerService.enable) {
                    this.spinnerService.disable();
                }
                if (err && err.error.responseCode === 'RES.40100') {
                    this.messageService.notify('error', this.errorText, err.error.responseMessage);
                } else {
                    this.messageService.notify('error', this.errorText, this.authExpiredText);
                }

            },
            0,
        );
    }

    private showError(e) {

        const isPrivacyCheck = e.url.includes('/check');
        const msg = _.get(e, 'error.responseMessage')
            || _.get(e.error, 'techMessage', e.error['techMessage']) || _.get(e, 'statusText', 'Unknown error');
        setTimeout(
            () => {
                if (!isPrivacyCheck) {
                    // Temporarily commented out to display bulk upload error messages.
                    // this.messageService.notify('error', this.errorText, msg);
                }
            },
            0,
        );
    }

    private isProtectedApiCall(req: HttpRequest<any>) {
        const base = this.constService.getBaseApiUrl();
        const login = this.constService.getLoginUrl();
        return req.url.startsWith(base) && !req.url.startsWith(login);
    }
    checkTokenExpiry() {
        if (localStorage.getItem('tokenExpiresIn')) {
            this.flag = false;
            this.tokenExpiresIn = +localStorage.getItem('tokenExpiresIn');
            const ng2IdleExpiry = +localStorage.getItem('ng2Idle.main.expiry');
            const currentTime = new Date();
            const currentTimeInSec = currentTime.getTime();
            const diffMs = (ng2IdleExpiry - this.tokenExpiresIn);
            const diffMins = Math.round(diffMs / 60000); // minutes
            // console.log('time1', new Date(this.tokenExpiresIn), 'time2', new Date(currentTimeInSec), 'Min--', diffMins);
            // if (currentTimeInSec > this.tokenExpiresIn && (diffMins >= 18 && diffMins <= 22)) {
            // if (currentTimeInSec > this.tokenExpiresIn && (diffMins >= 0 && diffMins <= 18)) {
            if (currentTimeInSec > this.tokenExpiresIn && (diffMins >= 18 && diffMins <= 22)) {
                this.flag = true;
                console.log('----Time passed. get new token---');
                this._payReportingService.broadcast('showConfirmDialog', true);

            } else if (currentTimeInSec > this.tokenExpiresIn && (diffMins >= 0 && diffMins <= 18)) {
                this.flag = true;
                console.log('----IDLE-----logout---');
                this._payReportingService.broadcast('showNgIdleConfirmDialog', true);
            } else {

                setTimeout(() => { this.checkTokenExpiry(); }, 3000);
            }
        }
    }

}
