import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpParams,
    HttpRequest,
    HttpEvent,
    HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    environment,
    KfSharedConstantsService,
    KfAuthService,
} from '@kf-products-core/kfhub_lib';
import { Url } from 'url';
import { FileUploadService } from './file-upload.service';
import * as md5 from 'md5';

@Injectable()
export class PostProcessingService {
    public environment = environment();
    public config = environment().validationApp;
    private pdcBaseUrl = this.config.pdcBaseApiUrl;
    private pdcBaseUrlSecondary = this.config.pdcBaseApiUrlSecondary;
    constructor(
        private authService: KfAuthService,
        private http: HttpClient,
        private constService: KfSharedConstantsService
    ) {}
    public getPresignUploadURL(loggedInClientId, filename, overrideEnvironment?: string): Observable<any> {
        // loggedInClientId
        // fileName
        // userId
        const lambdaBaseURL = this.config.presignAnyApiUrl;
        const authToken = this.authService.AuthToken;
        const headers = new HttpHeaders({
            authToken,
            'Content-Type': 'text/plain',
        });
        const presignType = 'putObject';
        const body = {
            filename,
            clientId: loggedInClientId,
            authToken,
            environment: (overrideEnvironment) ? overrideEnvironment : this.config.environment,
            userId: this.authService.UserId,
            presignType,
        };
        const url = lambdaBaseURL + '/presignAny';
        const options = {
            headers,
        };
        const req = new HttpRequest('POST', url, body, options);
        return this.http.request(req);
    }
    public s3Copy(loggedInClientId, sourceKey, targetKey): Observable<any> {
        const lambdaBaseURL = this.config.presignAnyApiUrl;
        const authToken = this.authService.AuthToken;
        const body = {
            authToken,
            userId: this.authService.UserId,
            environment: this.config.environment,
            clientId: loggedInClientId,
            sourceKey,
            targetKey,
        };
        const headers = new HttpHeaders({
            authToken,
            'Content-Type': 'text/plain',
        });
        const options = {
            headers,
        };
        const url = lambdaBaseURL + '/filecopy';
        const req = new HttpRequest('POST', url, body, options);
        return this.http.request(req);
    }
    public initializeT3Upload(body) {
        const url =
            this.environment.baseApiUrl +
            '/v1/hrms/paydatacollection/postprocessing/tier3/uploads';
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
            'Content-Type': 'application/json',
        });
        const options = {
            headers,
        };
        body.action = 'STATUS_UPDATE';
        console.log('initalizet3uploadBody', body);
        const req = new HttpRequest('POST', url, body, options);
        return this.http.request(req);
    }
    public updateT3Upload(body) {
        const url =
            this.environment.baseApiUrl +
            '/v1/hrms/paydatacollection/postprocessing/tier3/uploads';
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
            'Content-Type': 'application/json',
        });
        const options = {
            headers,
        };
        body.action = 'STATUS_UPDATE';
        console.log('initalizet3uploadBody', body);
        const req = new HttpRequest('PUT', url, body, options);
        return this.http.request(req);
    }

    public sendDirectUploadSQSMessage(body) {
        const lambdaBaseURL = this.config.presignAnyApiUrl;
        // const lambdaBaseURL = 'http://127.0.0.1:3000';
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
            'Content-Type': 'text/plain',
        });
        const options = {
            headers,
        };
        const url = lambdaBaseURL + '/directuploadpostsqs';
        const req = new HttpRequest('POST', url, body, options);
        return this.http.request(req);
    }
    public getDirectUploadUUID(fileName) {
        return md5(fileName + new Date());
    }
    public uploadfileAWSS3(fileUploadURL, contenttype, file): Observable<any> {
        const headers = { 'Content-Type': contenttype };
        const options = {
            Headers: headers,
            reportProgress: true, // This is required for track upload process
        };
        const req = new HttpRequest('PUT', fileUploadURL, file, options);
        return this.http.request(req);
    }
    public updateLastDownload(region, uuid, body) {
        console.log('REGIUON TRACKL');
        console.log(region);
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
            'Content-Type': 'application/json',
        });
        const options = {
            headers,
        };
        let url = this.pdcBaseUrl + 'datasubmissions/' + uuid;
        if (region === 'secondary') {
            url = this.pdcBaseUrlSecondary + 'datasubmissions/' + uuid;
        }
        const req = new HttpRequest('PUT', url, body, options);
        return this.http.request(req);

    }

    upload(
        url: string,
        file: File,
        reportProgress = false
    ): Observable<HttpEvent<any>> {
        const formData = new FormData();
        formData.append('upload', file);

        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });

        const options = {
            reportProgress,
            headers,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.http.request(req);
    }

    public presignDownload(
        loggedInClientId,
        filename,
        countryCode?: string,
        overrideEnvironment?: string,
    ): Observable<any> {
        // loggedInClientId
        // fileName
        // userId
        const lambdaBaseURL = this.config.presignAnyApiUrl;
        const authToken = this.authService.AuthToken;
        const headers = new HttpHeaders({
            authToken,
            'Content-Type': 'text/plain',
        });
        const presignType = 'getObject';
        const body = {
            filename,
            clientId: loggedInClientId,
            authToken,
            environment: overrideEnvironment ? overrideEnvironment : this.config.environment,
            userId: this.authService.UserId,
            presignType,
        };
        if (countryCode) {
            body['country'] = countryCode;
        }
        const url = lambdaBaseURL + '/presignAny';
        const options = {
            headers,
        };
        const req = new HttpRequest('POST', url, body, options);
        return this.http.request(req);
    }
    public getCountries(): Observable<any> {
        return null;
    }
    public getClientUploads(): Observable<any> {
        return null;
    }
    public getUpload(): Observable<any> {
        return null;
    }
    public getAllPP(countryCode,event = null): Observable<any> {
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        let url = this.pdcBaseUrl + 'datasubmissions';
        if (countryCode) {
            url += `?countryCode=${encodeURIComponent(countryCode)}`;
        }
        if (event) {
            if (event && (event.pageNumber)) {
                url +=  `&pageNumber=${encodeURIComponent(event.pageNumber)}`
            }
            if (event && event.rows) {
                url +=  `&pageSize=${encodeURIComponent(event.rows)}`
            }
            if (event && event.globalFilter) {
                url +=  `&searchString=${encodeURIComponent(event.globalFilter)}`
            }
        }
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getAllPPSecondary(countryCode,event = null): Observable<any> {
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        let url = this.pdcBaseUrlSecondary + 'datasubmissions';
        if (countryCode) {
            url += `?countryCode=${encodeURIComponent(countryCode)}`;
        }
        if (event) {
            if (event && (event.pageNumber)) {
                url +=  `&pageNumber=${encodeURIComponent(event.pageNumber)}`
            }
            if (event && event.rows) {
                url +=  `&pageSize=${encodeURIComponent(event.rows)}`
            }
            if (event && event.globalFilter) {
                url +=  `&searchString=${encodeURIComponent(event.globalFilter)}`
            }
        }
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }

    public getPP(uuid, region): Observable<any> {
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        let url = this.pdcBaseUrl + 'datasubmissions/' + uuid;
        if (region === 'secondary') {
            url = this.pdcBaseUrlSecondary + 'datasubmissions/' + uuid;
        }
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public keepAlive(): Observable<any> {
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        const url = this.environment.baseApiUrl + '/v1/actions/istokenvalid';
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public dataSourceLookup(countryCode: String): Observable<any> {
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        const url =
            this.environment.baseApiUrl +
            `/v1/hrms/paydatacollection/postprocessing/denormdatabases/metadata?countryCode=${countryCode}`;
        // console.log('dataSourceLookupurl' + url);
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }

    public changeFileStatus(statusIn, uuid): Observable<any> {
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        const url = this.pdcBaseUrl + 'datasubmissions/' + uuid;
        const dataToSend = { status: statusIn };
        const req = new HttpRequest('PUT', url, dataToSend, options);
        return this.http.request(req);
    }
    public initiatePackaging(data): Observable<any> {
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        const url = this.pdcBaseUrl + 't3Processing/initiatepackaging';
        const req = new HttpRequest('POST', url, data, options);
        return this.http.request(req);
    }

    public getUploadInfo(uuid): Observable<any> {
        const headers = new HttpHeaders({
            authtoken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        let url = 'https://b85mvvcbx1.execute-api.us-east-1.amazonaws.com/test'; // TODO: CONSTANTS
        url += '/:uuid:/info';
        url = url.replace(':uuid:', uuid);
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getPayReferenceDataLite(): Observable<any> {
        const base = this.constService.getBaseApiUrl();
        const url = base + '/v1/actions/payreferencedata?outputType=LITE';
        return this.authService.authHttpCall('get', url);
    }
}
