import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KfSharedConstantsService, KfAuthService } from '@kf-products-core/kfhub_lib';

@Injectable()
export class FileUploadService {
    constructor(
        private authService: KfAuthService,
        private http: HttpClient,
        private constService: KfSharedConstantsService) { }

    upload(path: string, file: File, reportProgress = false): Observable<HttpEvent<any>> {
        const base = this.constService.getBaseApiUrl();
        const url = base + '/' + path;
        const formData = new FormData();
        formData.append('upload', file);

        const headers = new HttpHeaders(
            { authToken: this.authService.AuthToken },
        );

        const options = {
            reportProgress,
            headers,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.http.request(req);
    }

}
