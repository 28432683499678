import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Toast } from 'primeng/toast';
import { MessageService } from '../shared-services/message.service';

@UntilDestroy()
@Component({
    selector: 'kf-hubint-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {

    @ViewChild(Toast) toast: Toast;
    constructor(private messageService: MessageService) { }

    ngOnInit() {
        this.messageService.notificationChange
            .pipe(untilDestroyed(this))
            .subscribe((notifications) => {

                this.toast.add([notifications]);
            });
    }

    ngOnDestroy() {
    }
}
