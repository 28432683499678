
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    KfAuthService,
    KfSharedConstantsService,
    KfFilterMetadata,
    KfImetaSearchDataService,
} from '@kf-products-core/kfhub_lib';
import { Observable } from 'rxjs';

@Injectable()
export class UsersMetadataService implements KfImetaSearchDataService {
    private readonly usersPrefix = '/v2admin/adminuser/config';

    constructor(private authService: KfAuthService, private constService: KfSharedConstantsService) { }

    public getMetadata(): Observable<KfFilterMetadata[]> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.usersPrefix + '?outputType=METADATA';
        return this.authService.authHttpCall('get', url).pipe(map((d = {}) => d.metadata));
    }
}
