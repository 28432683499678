// tslint:disable
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment, KfSharedConstantsService, KfAuthService } from '@kf-products-core/kfhub_lib';
import { Url } from 'url';
import { FileUploadService } from './file-upload.service';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Injectable()
export class PostProcessingT3Service {
    public config = environment().validationApp;
    private baseUrl = environment().baseApiUrl;
    constructor(
        private authService: KfAuthService,
        private http: HttpClient,
        private constService: KfSharedConstantsService,
        public spinner: SpinnerVisibilityService
    ) {}
    public getPresignUploadURL(loggedInClientId, filename): Observable<any> {
        // loggedInClientId
        // fileName
        // userId
        const lambdaBaseURL = this.config.presignAnyApiUrl;
        const authToken = this.authService.AuthToken;
        const headers = new HttpHeaders({
            authToken,
            "Content-Type": "text/plain",
        });
        const presignType = "putObject";
        const body = {
            filename,
            clientId: loggedInClientId,
            authToken,
            environment: this.config.environment,
            userId: this.authService.UserId,
            presignType,
        };
        const url = lambdaBaseURL + "/presignAny";
        const options = {
            headers,
        };
        const req = new HttpRequest("POST", url, body, options);
        return this.http.request(req);
    }
    public s3Copy(loggedInClientId, sourceKey, targetKey): Observable<any> {
        const lambdaBaseURL = this.config.presignAnyApiUrl;
        const authToken = this.authService.AuthToken;

        const body = {
            authToken,
            userId: this.authService.UserId,
            environment: this.config.environment,
            clientId: loggedInClientId,
            sourceKey,
            targetKey,
        };
        const headers = new HttpHeaders({
            authToken,
            "Content-Type": "text/plain",
        });
        const options = {
            headers,
        };
        const url = lambdaBaseURL + "/filecopy";
        const req = new HttpRequest("POST", url, body, options);
        return this.http.request(req);
    }

    public uploadfileAWSS3(fileUploadURL, contenttype, file): Observable<any> {
        const headers = { "Content-Type": contenttype };
        const options = {
            Headers: headers,
            reportProgress: true, // This is required for track upload process
        };
        const req = new HttpRequest("PUT", fileUploadURL, file, options);
        return this.http.request(req);
    }

    upload(
        url: string,
        file: File,
        reportProgress = false
    ): Observable<HttpEvent<any>> {
        const formData = new FormData();
        formData.append("upload", file);

        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });

        const options = {
            reportProgress,
            headers,
        };

        const req = new HttpRequest("POST", url, formData, options);
        return this.http.request(req);
    }

    public presignDownload(loggedInClientId, filename): Observable<any> {
        // loggedInClientId
        // fileName
        // userId
        const lambdaBaseURL = this.config.presignAnyApiUrl;
        const authToken = this.authService.AuthToken;
        const headers = new HttpHeaders({
            authToken,
            "Content-Type": "text/plain",
        });
        const presignType = "getObject";
        const body = {
            filename,
            clientId: loggedInClientId,
            authToken,
            environment: this.config.environment,
            userId: this.authService.UserId,
            presignType,
        };
        const url = lambdaBaseURL + "/presignAny";
        const options = {
            headers,
        };
        const req = new HttpRequest("POST", url, body, options);
        return this.http.request(req);
    }
    public getCountries(): Observable<any> {
        return null;
    }
    public getClientUploads(): Observable<any> {
        return null;
    }
    public getUpload(): Observable<any> {
        return null;
    }
    public getAllBatches(): Observable<any> {
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        const url =
            this.baseUrl +
            "/v1/hrms/paydatacollection/postprocessing/incumbentuploads/batches?_=" +
            +new Date();
        const req = new HttpRequest("GET", url, "", options);
        return this.http.request(req);
    }

    public getBatch(batchId) {
        // MOCK
        return {
            tier3Files: [
                {
                    fileId: "1514",
                    fileName: "DK004.xlsx",
                    bucketRegion: null,
                    bucketName: "kfhub-dataupload-us-test",
                    status: "Ready for Processing",
                    effectiveDate: "2019-09-13 12:09:00.0",
                    importCategory: "INCUMBENT",
                    importType: "REPLACE",
                    dataSource: "test_dk_190913",
                    countryCode: "DK",
                    archivePath:
                        "readyForProcessing/07346aa9d2b32a427ba7629dc97b2770.zip",
                    numberOfIncumbents: "22",
                    factCalculation: "true",
                    uploadDateTimestamp: 1568406572450,
                    uploadBy: "amarender rallabandi",
                    statusCode: "READY_FOR_PROCESSING",
                },
                {
                    fileId: "1513",
                    fileName: "input_Belgium_facts 100k_rows.xlsb",
                    bucketRegion: null,
                    bucketName: "kfhub-dataupload-us-test",
                    status: "Ready for Processing",
                    effectiveDate: "2019-09-11 12:09:00.0",
                    importCategory: "INCUMBENT",
                    importType: "NEW",
                    dataSource: "TEST",
                    countryCode: "BE",
                    archivePath:
                        "readyForProcessing/9795830ee3c83deb42f2adec3a2e6ba6.zip",
                    numberOfIncumbents: "100208",
                    factCalculation: "true",
                    uploadDateTimestamp: 1568225600303,
                    uploadBy: "ORGDC SuperUser",
                    statusCode: "READY_FOR_PROCESSING",
                },
            ],
        };
    }
    public getAllPP(): Observable<any> {
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        const url =
            this.baseUrl +
            "/v1/hrms/paydatacollection/datasubmissions/tier3?_=" +
            +new Date();
        const req = new HttpRequest("GET", url, "", options);
        return this.http.request(req);
    }
    public getTier3Import(batchId): Observable<any> {
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        let url =
            this.baseUrl +
            "/v1/hrms/paydatacollection/postprocessing/tier3/uploads";
        if (batchId) {
            url =
                `${this.baseUrl}/v1/hrms/paydatacollection/postprocessing/incumbentuploads/batches/${batchId}?_=` +
                +new Date();
        }
        const req = new HttpRequest("GET", url, "", options);
        return this.http.request(req);
    }
    public getT3ClientByCountryCode(countryCode): Observable<any> {
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        const url =
            this.baseUrl +
            "/v1/hrms/paydatacollection/datasubmissions/tier3/clients?countryCode=" +
            countryCode;
        const req = new HttpRequest("GET", url, "", options);
        return this.http.request(req);
    }

    public getPP(uuid): Observable<any> {
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        const url =
            "https://dep926rjy3.execute-api.us-east-1.amazonaws.com/Prod/orgdc/datasubmissions/" +
            uuid;
        const req = new HttpRequest("GET", url, "", options);
        return this.http.request(req);
    }
    public batchZip({
        batchEntries,
        batchId,
        s3Bucket,
        awsRegion,
        keyNameLookup,
    }) {
        const lambdaBaseURL = this.config.presignAnyApiUrl;
        // const lambdaBaseURL = 'http://127.0.0.1:3000';
        const url = `${lambdaBaseURL}/s3zip`;
        const headers = new HttpHeaders({
            "Content-Type": "text/plain",
            authtoken: this.authService.AuthToken,
        });
        const options = { headers };
        const body = {
            srcKeys: batchEntries.map((entry) => {
                return entry.s3UploadPath;
            }),
            keyNameLookup,
            dstKey: `bulkUploadArchive/batch${batchId}.zip`,
            s3Bucket,
            awsRegion,
        };
        console.log("generateZipBody");
        console.log(body);
        const req = new HttpRequest("POST", url, body, options);

        return this.http.request(req);
    }
    public getUploadInfo(uuid): Observable<any> {
        const headers = new HttpHeaders({
            authtoken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        let url = "https://b85mvvcbx1.execute-api.us-east-1.amazonaws.com/test"; // TODO CONSTANTS
        url += "/:uuid:/info";
        url = url.replace(":uuid:", uuid);
        const req = new HttpRequest("GET", url, "", options);
        return this.http.request(req);
    }

    getT2Reports() {
        const headers = new HttpHeaders({
            authtoken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        const url =
            this.baseUrl +
            "/v1/hrms/paydatacollection/datasubmissions/reports?_=" +
            +new Date();
        const req = new HttpRequest("GET", url, "", options);
        return this.http.request(req);
    }

    downloadReport(queryParams, data) {
        this.spinner.show();
        const params = data;

        const fullUrl =
            this.baseUrl +
            "/v1/hrms/paydatacollection/datasubmissions/reports/download?" +
            queryParams;
        const xhr = new XMLHttpRequest();
        xhr.open("GET", fullUrl, true);
        xhr.setRequestHeader("Content-Type", "text/csv"); // application/json
        xhr.setRequestHeader("authToken", this.authService.AuthToken);

        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                console.log("Response");
                const response = xhr.response;
                this.generateFileFromBlob(response, params);
                this.spinner.hide();
            }
        };
        xhr.responseType = "blob";
        xhr.send();
    }

    generateFileFromBlob(blob, params) {
        console.log("downloadf");
        const fileName = params.name + ".csv";
        // TODO: IE detection
        let isIE = false;
        const match = navigator.userAgent.search(
            /(?:Edge|MSIE|Trident\/.*; rv:)/
        );
        let isMozilla = false;
        if (navigator.userAgent.toLowerCase().indexOf("firefox") > -1) {
            isMozilla = true;
        } else if (match !== -1) {
            isIE = true;
        }
        try {
                if (isMozilla) {
                    const file = new File([blob], fileName, {
                        type: "application/force-download",
                    });
                    window.open(URL.createObjectURL(file));
                } else {
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                }
           
        } catch (e) {}
    }
    public pushtoCalcEngine(data): Observable<any> {
        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        const url =
            this.baseUrl +
            "/v1/hrms/paydatacollection/postprocessing/calcengine/publish";
        // const dataToSend = { status: statusIn };
        const req = new HttpRequest("POST", url, data, options);
        return this.http.request(req);
    }
    public getCountrieBasedOnModule(moduleName) {
        const headers = new HttpHeaders({
            authtoken: this.authService.AuthToken,
            authToken: this.authService.AuthToken,
        });
        const options = {
            headers,
        };
        const url =
            this.baseUrl +
            "/v1/hrms/paydatacollection/postprocessing/actions/countries?moduleType=" +
            moduleName;
        const req = new HttpRequest("GET", url, "", options);
        return this.http.request(req);
    }

    reprocessFile(data: any) {
        const url = this.baseUrl + "/v1/hrms/paydatacollection/postprocessing/tier3/uploads";
        return this.authService.authHttpCall("POST", url, data, null, true);
    }
}
