import { Injectable } from '@angular/core';
import { environment, KfAuthService } from '@kf-products-core/kfhub_lib';

@Injectable()
export class CustomGradeService {
    public postprocessing = '/v1/hrms/postprocessing/';
    public baseApiUrl = environment().baseApiUrl;
    public reports = '/v1/hrms/reports/';

    constructor(private authService: KfAuthService) { }

    public getCustomGrades (apiPath: string) {
        const serverUrl = this.baseApiUrl + this.postprocessing + apiPath;
        return this.authService
      .authHttpCall('GET', serverUrl, null, null, true);
    }

    public viewCustomGradeSet(gradeSetId) {
        const serverUrl =
      this.baseApiUrl +
      this.postprocessing +
      'customgrades/customgradesetdetails/' + gradeSetId;
        return this.authService
      .authHttpCall('GET', serverUrl, null, null, true);
    }

    public deleteCustomGradesetDetails(customeGradeSetId) {
        const serverUrl =
      this.baseApiUrl +
      this.postprocessing +
      'customgrades/customgradesetdetails/' + customeGradeSetId;
        return this.authService
      .authHttpCall('DELETE', serverUrl, null, null, true);
    }

    public getUserAccessCountryCG() {
        const serverUrl =
      this.baseApiUrl +
      '/v1/references/countries';
        return this.authService
      .authHttpCall('GET', serverUrl, null, null, true);
    }

    public addCustomGradesetDetails(body) {
        const serverUrl =
      this.baseApiUrl +
      this.postprocessing +
      'customgrades/customgradesetdetails/';
        const data = {
            countryCode: body.countryCode,
            gradeSetName: body.gradeSetName,
            gradeSetStandard: (body.countryCode === '00') ? 1 : 2,
            isDefault: (body.isDefault) ? true : false,
            grades: body.grades,
        };
        return this.authService.authHttpCall('POST', serverUrl, data, { 'Content-Type': 'application/json' }, true);
    }

    public updateCustomGradesetDetails(body) {
        const serverUrl =
      this.baseApiUrl +
      this.postprocessing +
      'customgrades/customgradesetdetails/';

        if (body && body.grades) {  // remove optional data
            body.grades.forEach((grade) => {
                if (grade.middle === null || grade.middle === undefined || grade.middle === '') {
                    grade.middle = 0;
                }
            });
        }

        const data = {
            module: (body.module) ? body.module : 'update',
            gradeSetId: body.gradeSetId,
            countryCode: body.countryCode,
            gradeSetName: body.gradeSetName,
            gradeSetStandard: (body.countryCode === '00') ? 1 : body.gradeSetStandard,
            isDefault: (body.isDefault) ? true : false,
            grades: body.grades,
        };

        return this.authService.authHttpCall('PUT', serverUrl, data, { 'Content-Type': 'application/json' }, true);
    }

}

