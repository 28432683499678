import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment, KfSharedConstantsService, KfAuthService, KfFilterMetadata, KfTRequestLoadingTypes } from '@kf-products-core/kfhub_lib';
import { SpinnerVisibilityService } from 'ng-http-loader';
import * as _ from 'lodash';

@Injectable()
export class KFAManagementService {

    public config = environment().validationApp;
    private baseUrl = environment().baseApiUrl;
    loggedInClientId: any;
    userId: any;
    kfaBaseUrl: string;

    constructor(
        private authService: KfAuthService,
        private http: HttpClient,
        private constService: KfSharedConstantsService,
        public spinner: SpinnerVisibilityService) {
        this.loggedInClientId = this.authService.getSessionInfo().User.ClientId;
        this.userId = this.authService.getSessionInfo().User.UserId;
        this.baseUrl = this.constService.getBaseApiUrl();
        this.kfaBaseUrl = this.baseUrl + '/v1/hrms/thportal/admin/jobs/';
    }

    // tslint:disable-next-line: max-line-length
    public search(searchString: string, appliedFilters: KfFilterMetadata[], sorting: any[], pageIndex: number = 0, pageSize: number = 20) {
        this.spinner.show();
        const url = this.kfaBaseUrl + 'clients?';
        const paramsData = {
            loggedInUserClientId: this.loggedInClientId,
            userId: this.userId,
            locale: 'en',
            pageIndex: pageIndex.toString(),
            pageSize: pageSize.toString(),
            searchString,
            // searchColumn: '',
            // sortBy: '',
            // sortColumn: '',
        };
        if (searchString == null || searchString.trim() === '') {
            delete paramsData.searchString;
        }
        const params = new HttpParams({ fromObject: paramsData });
        this.spinner.hide();
        return this.authService.authHttpCall('get', url + params.toString());
    }

    uploadUrl() {
        const selectedClientInfo = JSON.parse(sessionStorage.getItem('selected-client'));
        const pamsId = Number(selectedClientInfo.PamsID);
        const url = this.kfaBaseUrl + 'uploadurl?&clientId=' + pamsId;
        return this.authService.authHttpCall('get', url);
    }

    public uploadFileAWSS3(fileUploadURL, contentType, file): Observable<any> {
        const config = {
            headers: { 'Content-Type': contentType },
        };
        return this.http.put(fileUploadURL, file, config);
    }

    public getUploadStatus(fileUUID: string): Observable<any> {
        const selectedClientInfo = JSON.parse(sessionStorage.getItem('selected-client'));
        const pamsId = Number(selectedClientInfo.PamsID);
        const url = this.kfaBaseUrl + 'updatestatus?&clientId=' + pamsId + '&fileUUID=' + fileUUID;
        return this.authService.authHttpCall('get', url);
    }

    public downloadFileWithStatus(fileUUID: string, status: number): Observable<any> {
        const selectedClientInfo = JSON.parse(sessionStorage.getItem('selected-client'));
        const pamsId = Number(selectedClientInfo.PamsID);
        let url;
        if (fileUUID.length === 0) {
            url = this.kfaBaseUrl + 'downloadurl?&status=' + status + '&clientId=' + pamsId;
        } else {
            url = this.kfaBaseUrl + 'downloadurl?&clientId=' + pamsId + '&fileUUID=' + fileUUID + '&status=' + status;
        }
        return this.authService.authHttpCall('get', url);
    }

    public prepareDownload(): Observable<any> {
        const selectedClientInfo = JSON.parse(sessionStorage.getItem('selected-client'));
        const pamsId = Number(selectedClientInfo.PamsID);
        const url = this.kfaBaseUrl + 'kfajobsexport?&clientId=' + pamsId;
        return this.authService.authHttpCall('POST', url, { status: 0 });
    }

    public downloadKfaJobs(fileUUID: string, requestType?: KfTRequestLoadingTypes): Observable<any> {
        const selectedClientInfo = JSON.parse(sessionStorage.getItem('selected-client'));
        const pamsId = Number(selectedClientInfo.PamsID);
        const url = this.kfaBaseUrl + 'kfajobsexport?&clientId=' + pamsId + '&fileUUID=' + fileUUID;
        return this.authService.authHttpCallv2('get', url, undefined, { requestType });
    }

    public pushData(data): Observable<any> {
        const url = this.kfaBaseUrl + 'pushData';
        return this.authService.authHttpCall('POST', url, data);
    }
}
