
import {concatMap} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment, KfAuthService } from '@kf-products-core/kfhub_lib';

export interface PresignAnyData {
    country: string;
    clientId: number;
    filename: string;
    uniqueFilename: string;
    presignedUrl: string;
}

@Injectable()
export class S3FileService {

    private config = environment().validationApp;
    private environment: 'DEV' | 'TEST' | 'STAGE' | 'PROD' = this.config.environment;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private httpClient: HttpClient,
        private authService: KfAuthService,
    ) {}

    public getFileBucketPrefix(): string {
        return this.config.fileBucketURL;
    }
    public getFileBucketPrefixEU(): string {
        return this.config.fileBucketURLEU;
    }
    public getFileBucketPrefixCN(): string {
        return this.config.fileBucketURLCN;
    }

    public getCeProcessingBucketPrefix(): string {
        return this.config.ceProcessingBucketURL;
    }

    public presignAny(
        presignType: string,
        country: string,
        filename: string,
        mimeType?: string,
        env?: string,
    ): Observable<PresignAnyData> {
        const sessionInfo = this.authService.getSessionInfo();
        const clientId = sessionInfo.Client.ClientId;
        const userId = sessionInfo.User.UserId;

        const headers = new HttpHeaders({
            authToken: this.authService.AuthToken,
            'Content-Type': 'text/plain',
        });
        const requestBody: { [key: string]: any; } = {
            authToken: this.authService.AuthToken,
            // environment: this.environment,
            environment: (env) ? env : this.environment,
            clientId,
            userId: 38446,
            presignType,
            country,
            filename,
        };
        if (mimeType) {
            requestBody.mimeType = mimeType;
        }
        return this.httpClient.post<PresignAnyData>(
            this.config.presignAnyApiUrl+'/presignAny',
            requestBody,
            {
                headers,
            },
        );
    }

    public upload(country: string, fileName: string, arrayBuffer: ArrayBuffer, env?: string): Observable<any> {
        return this.presignAny('putObject', country, fileName, null, env).pipe(
            concatMap((presignAnyData: PresignAnyData) => {
                // tslint:disable-next-line: max-line-length
                // if( presignAnyData.presignedUrl && presignAnyData.presignedUrl.includes('s3.eu-central-1.amazonaws.com')){
                //     presignAnyData.presignedUrl = presignAnyData.presignedUrl.replace('s3.eu-central-1.amazonaws.com','s3.amazonaws.com');
                // }
                return this.httpClient.put(
                    presignAnyData.presignedUrl,
                    arrayBuffer,
                );

            }));
    }

    public download(country: string, fileName: string): Observable<Blob> {
        return this.presignAny('getObject', country, fileName).pipe(
            concatMap((presignAnyData: PresignAnyData) => {
                return this.httpClient.get<Blob>(presignAnyData.presignedUrl, {
                    responseType: 'blob' as 'json',
                });
            }));
    }

}
