
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import * as _ from 'lodash';
import { PermissionsService } from './shared-services/permissions.service';
import { Observable } from 'rxjs';
import { MessageService } from './shared-services/message.service';
import { KfAuthService } from '@kf-products-core/kfhub_lib';

@Injectable()
export class AppPermissionsGuard  {
    private authService: KfAuthService
    private readonly namesToRoutesMap = {
        DASHBOARD: 'dashboard',
        BANNERS: 'banners',
        WORKDAY_COMP: 'competencies',
        PAY_HUB_MANAGEMENT: 'payhub',
        USER_ADMINISTRATION: 'permissions',
        VALIDATION_JSON: 'validation',
        PAYDATA_REPORT : 'paydata',
        PDC_VALIDATIONS_T2 : 'pdc-validations-t2',
        PDC_VALIDATIONS_T3: 'pdc-validations-t3',
        SCRIPTS:'scripts',
        KFUTILS : 'kfutils',
        PAY_REPORTS : 'pay-reports',
        BENEFITS_PREVALENCE : 'benefits-prevalence',
        TALENT_HUB_MANAGEMENT: 'th-management',
        EMAIL_MANAGEMENT : 'email-management',
        SECTOR_CODING_TOOL: 'sector-coding-tool',
        PDC_T2_FILE_UPLOAD: 'pdc-t2-file-upload',
        QUALTRICS_REPORT : 'qualtrics',
        RIS_REPORTING: 'ris-reports',
    };

    private readonly routeToNamesMap = _(this.namesToRoutesMap).toPairs().map(_.reverse).fromPairs().value();

    constructor(
        private router: Router,
        private permissionsService: PermissionsService,
        private messageService: MessageService,
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const routeName = _.get(_.first(route.url), 'path');
        const appName = this.routeToNamesMap[routeName];
        return this.permissionsService.getOwnPermissions().pipe(
            map((permissions) => {
                if (routeName && _.get(permissions, `applications.${appName}`)) {
                    // on client Tracking refresh page, Force tab to retain on the same page;
                    if (window.location.pathname?.includes('/client-tracking/')) {
                        setTimeout(() => {
                            window.open(`${window.location.origin }/client-tracking?token=${this.authService.getSessionInfo().User.AuthToken}&userId=${this.authService.getSessionInfo().User.UserId}`)
                        },100);
                        return true;
                      }
                    return true;
                } else {
                    if (appName) {
                        this.messageService.notify('error', 'Error', `You have no permissions to access ${appName}`);
                    }
                    const accessibleApplication = _.findKey(permissions.applications, _.identity);
                    if (accessibleApplication) {
                        this.router.navigate([`/${this.namesToRoutesMap[accessibleApplication]}`]);
                    } else {
                        this.router.navigate(['/login']);
                    }
                    return false;
                }
            }));
    }
}
