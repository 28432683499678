import { Injectable } from '@angular/core';
import { KfFilterMetadata } from '@kf-products-core/kfhub_lib';
import * as _ from 'lodash';

@Injectable()
export class FilterMetadataConverterService {
    constructor() { }

    /**
     * Converts KfFilterMetadata to format usable for HttpParams
     * subtable for backend API
     */
    toQueryParamsObject(appliedFilters: KfFilterMetadata[]) {
        const groupedData = _.mapValues(_.groupBy(appliedFilters, 'type'), g => _.map(g, 'id'));
        const [searchKeys, seachValues] = _.zip(...(_.toPairs(groupedData)));
        const filterBy = _.join(searchKeys, '|');
        const filterValues = _.join(_.map(seachValues, vals => _.join(vals, ';')), ' ');
        return { filterBy, filterValues };
    }

}
