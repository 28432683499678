
import {concatMap} from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { KfAuthService, KfSharedConstantsService, environment, KfIdleService } from '@kf-products-core/kfhub_lib';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Observable, zip } from 'rxjs';
import { PermissionsService } from '../shared-services/permissions.service';


@UntilDestroy()
@Component({
    selector: 'kf-hubint-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {
    public isMenuVisible = false;
    public isDropdownVisible = false;
    public menuItems: MenuItem[];
    public appUrlPrefix: string = environment().appUrlPrefix;
    private config = environment().validationApp;
    public environment: 'DEV' | 'TEST' | 'STAGE' | 'PROD' | 'PROD-EU' | 'PROD-AP' = this.config.environment;
    public username = null;

    constructor(
        private router: Router,
        private authService: KfAuthService,
        private translate: TranslateService,
        private permissionsService: PermissionsService,
        private constService: KfSharedConstantsService,
        public idleService: KfIdleService,
    ) { }

    public logout() {
        const base = this.constService.getBaseApiUrl();
        const url = base + '/v1/actions/logout';

        this.authService.authHttpCall('PUT', url, {}).pipe(
            concatMap((e) => {
                return this.authService.removeSessionInfo();
            }))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.idleService.stopIdleWatch();
                this.router.navigate(['login']);
            });
    }

    public showMenu() {
        this.isMenuVisible = true;
    }

    public hideMenu() {
        this.isMenuVisible = false;
    }

    public showNavDropdown() {
        this.isDropdownVisible = !this.isDropdownVisible;
    }

    ngOnInit() {
        this.username = this.authService.getSessionInfo()?.User?.FirstName;
        
        zip(
            this.translate.get('applications'),
            this.translate.get('workDayIntApp'),
            this.translate.get('payHubManageApp'),
            this.translate.get('permsManageApp'),
            this.translate.get('validationJsonApp'),
            this.translate.get('PDCt2Validation'),
            this.translate.get('PDCt3Validation'),
            this.translate.get('kfutils.kfutilsTitle'),
            this.translate.get('paydataReport'),
            this.permissionsService.getOwnPermissions(),
            this.translate.get('th-management.talentHubManagement'),
            this.translate.get('email-management'),
            this.translate.get('t2-validation-rules'),
            this.translate.get('sector-coding-tool'),
            this.translate.get('ris-reporting'),
            this.translate.get('qualtrics-report'),
        ).pipe(
            untilDestroyed(this),
        ).subscribe(([label, wd, ph, pm, vj, PDCT2, PDCT3, kf, pr, permissions, thm, em, t2v, sct, ris,qt]) => {
            const items = [];
            if (permissions.applications) {
                if (permissions.applications['WORKDAY_COMP']) {
                    items.push({
                        label: wd,
                        routerLink: '/competencies',
                        command: () => this.hideMenu(),
                    });
                }
                if (permissions.applications['PAY_HUB_MANAGEMENT']) {
                    items.push({
                        label: ph,
                        routerLink: '/payhub',
                        command: () => this.hideMenu(),
                    });
                }
                if (permissions.applications['USER_ADMINISTRATION']) {
                    items.push({
                        label: pm,
                        routerLink: '/permissions',
                        command: () => this.hideMenu(),
                    });
                }
                if (permissions.applications['VALIDATION_JSON']) {
                    items.push({
                        label: vj,
                        routerLink: '/validation',
                        command: () => this.hideMenu(),
                    });
                }
                if (permissions.applications['PDC_VALIDATIONS_T2']) {
                    items.push({
                        label: PDCT2,
                        routerLink: '/pdc-validations-t2',
                        command: () => this.hideMenu(),
                    });
                }
                if (permissions.applications['BANNERS']) {
                    items.push({
                        label: 'Banners',
                        routerLink: '/banners',
                        command: () => this.hideMenu(),
                    });
                }
                if (permissions.applications['PDC_VALIDATIONS_T3']) {
                    items.push({
                        label: PDCT3,
                        routerLink: '/pdc-validations-t3',
                        command: () => this.hideMenu(),
                    });
                }
                if (permissions.applications['KFUTILS']) {
                    items.push({
                        label: kf,
                        routerLink: '/kfutils',
                        command: () => this.hideMenu(),
                    });
                }
                if (permissions.applications['PAYDATA_REPORT']) {
                    items.push({
                        label: pr,
                        routerLink: '/paydata',
                        command: () => this.hideMenu(),
                    });
                }
                if (permissions.applications['TALENT_HUB_MANAGEMENT']) {
                    items.push({
                        label: thm,
                        routerLink: '/th-management',
                        command: () => this.hideMenu(),
                    });
                }
                if (permissions.applications['SECTOR_CODING_TOOL']) {
                    items.push({
                        label: sct,
                        routerLink: '/sector-coding-tool',
                        command: () => this.hideMenu(),
                    });
                }
                if (permissions.applications['PDC_T2_FILE_UPLOAD']) {
                    items.push({
                        label: t2v,
                        routerLink: '/pdc-t2-file-upload',
                        command: () => this.hideMenu(),
                    });
                }
                if (permissions.applications['EMAIL_MANAGEMENT']) {
                    items.push({
                        label: em,
                        routerLink: '/email-management',
                        command: () => this.hideMenu(),
                    });
                }
                if (permissions.applications['RIS_REPORTING']) {
                    items.push({
                        label: ris,
                        routerLink: '/ris-reports',
                        command: () => this.hideMenu(),
                    });
                }
                if (permissions.applications['QUALTRICS_REPORT']) {
                    items.push({
                        label: qt,
                        routerLink: '/qualtrics',
                        command: () => this.hideMenu(),
                    });
                }
            }
            this.menuItems = [{
                label,
                items,
            }];
            // if (permissions.applications.TALENT_HUB_MANAGEMENT) {
            //     this.menuItems.push({
            //         label: thm,
            //         routerLink: '/th-management',
            //         command: () => this.hideMenu(),
            //         items: [
            //             { label: 'Client List', routerLink: '/th-management', command: () => this.hideMenu() },
            //             { label: 'Sub Menu 2' },
            //         ],
            //     });
            // }
        });
    }

    ngOnDestroy() { }
}
