import { Injectable, OnDestroy } from '@angular/core';
import { Message } from 'primeng/api';
import { Subject } from 'rxjs';

type Severities = 'success' | 'info' | 'warn' | 'error';

@Injectable()
export class MessageService implements OnDestroy {

    protected isActive = true;
    protected checkMessagesRunnerId;
    protected lastId = 0;
    protected checkMessagesInterval = 2000;
    protected localStorageMessagePrefix = '_shared_message.';
    protected knownKeys: string[] = [];
    notificationChange = new Subject<Message>();

    constructor() {
        this.scheduleMessagesCheck(this.checkMessagesInterval);
    }

    ngOnDestroy(): void {
        this.isActive = false;
        this.knownKeys.length = 0;
        if (this.checkMessagesRunnerId) {
            clearTimeout(this.checkMessagesRunnerId);
            this.checkMessagesRunnerId = null;
        }
    }

    protected scheduleMessagesCheck(timeout: number): void {
        if (this.checkMessagesRunnerId) {
            clearTimeout(this.checkMessagesRunnerId);
        }
        this.checkMessagesRunnerId = setTimeout(() => this.checkMessages(), timeout);
    }

    protected checkMessages(): void {
        this.checkLocalStorageMessages();
        if (this.isActive) {
            this.scheduleMessagesCheck(this.checkMessagesInterval);
        }
    }

    protected checkLocalStorageMessages(): void {
        Object.keys(localStorage)
            .filter(key => !this.knownKeys.includes(key) && this.testLocalStorageKey(key))
            .forEach(key => {
                this.knownKeys.push(key);
                this.notifyOnLocalStorageMessage(localStorage.getItem(key));
                setTimeout(() => localStorage.removeItem(key), this.checkMessagesInterval - 100);
            });
    }

    protected notifyOnLocalStorageMessage(raw: string): void {
        try {
            const data = JSON.parse(raw);
            this.notify(data.severity, data.summary, data.detail, data);
        } catch (e) {
        }
    }

    protected testLocalStorageKey(key: string): boolean {
        return key.startsWith(this.localStorageMessagePrefix);
    }

    notify(severity: Severities, summary: string, detail: string, options: Partial<Message> = {}): void {
        this.lastId = this.lastId + 1;
        this.notificationChange.next({ ...options, id: this.lastId, severity, summary, detail });
    }
}
