import { SpinnerVisibilityService } from 'ng-http-loader';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    environment, KfAuthService, KfSharedConstantsService, KfSpinnerService
} from '@kf-products-core/kfhub_lib';

export interface AnalysisList {
    analysisName?: string;
    dataSource?: string;
    countryCode?: string;
    createdDate?: number;
}

@Injectable()
export class RisReportingService {
    public config = environment().validationApp;
    private baseUrl = environment().baseApiUrl;
    private relUrl = '/v1/hrms/threporting/reports';
    private _eventBus: Subject<any>;
    constructor(
        private authService: KfAuthService,
        private http: HttpClient,
        private constService: KfSharedConstantsService,
        public spinner: SpinnerVisibilityService,
        private spinnerService: KfSpinnerService) {
        this._eventBus = new Subject();

    }

    broadcast(key: String, data?: any) {
        this._eventBus.next({
            key, data,
        });
    }
    // the listener method
    on(key: String): Observable<any> {
        return this._eventBus.asObservable().pipe(
            filter(event => event.key === key),
            map(event => event.data),);
    }
    public getReportCategoryV1(): Observable<any> {
        const headers = new HttpHeaders(
            { authtoken: this.authService.AuthToken, authToken: this.authService.AuthToken },
        );
        const options = {
            headers,
        };
        const url = this.baseUrl + this.relUrl + '/reportcategory/?_=' + +new Date();
        const req = new HttpRequest('GET', url, '', options);
        return this.http.request(req);
    }
    public getReportCategory() {
        const serverUrl = this.baseUrl + this.relUrl + '/reportcategory/';

        return this.authService
            .authHttpCall('GET', serverUrl, null, null, true);
    }
    public getReportsByCategory(id) {
        const serverUrl = this.baseUrl + this.relUrl + '/reportbycategory?categoryId=' + id;

        return this.authService
            .authHttpCall('GET', serverUrl, null, null, true);
    }

}
