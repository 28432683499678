import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {
    environment, KfAuthGuardService, KfAuthService, KfComponentsModule, KfGrowlService,
    KfIdleService, KfPopupService, KfSharedConstantsService, KfSharedModule, KfToggleModule,
    environmentReader,
} from '@kf-products-core/kfhub_lib';
import { NgIdleModule } from '@ng-idle/core';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SpinnerVisibilityService } from 'ng-http-loader';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { MenuModule } from 'primeng/menu';
import { ProgressBarModule } from 'primeng/progressbar';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { CardModule } from 'primeng/card';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth-interceptor';
import { LoginComponent } from './login/login.component';
import { NavigationComponent } from './navigation/navigation.component';
import { NotificationComponent } from './notification/notification.component';
import { SharedServicesModule } from './shared-services/shared-services.module';
import { CookieService } from 'ngx-cookie-service';
import { KfButtonModule, KfIconModule, KfTextModule } from '@kf-products-core/kfhub_lib/presentation';
import { DragDropModule } from 'primeng/dragdrop';
import { OrderListModule } from 'primeng/orderlist';

export function httpLoaderFactory(http: HttpClient) {
    const prefix = environment().appUrlPrefix;
    return new TranslateHttpLoader(http, `${prefix}/assets/languages/`, '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NavigationComponent,
        NotificationComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        // NgbModule.forRoot(),
        NgIdleModule.forRoot(),
        AppRoutingModule,
        SharedServicesModule.forRoot(),
        SharedModule,
        SidebarModule,
        MenuModule,
        ToastModule,
        ConfirmDialogModule,
        DialogModule,
        ProgressBarModule,
        KfSharedModule,
        KfComponentsModule,
        KfIconModule,
        KfButtonModule,
        KfTextModule,
        KfToggleModule,
        CardModule,
        DragDropModule,
        OrderListModule,
    ],
    providers: [
        KfGrowlService,
        KfIdleService,
        KfPopupService,
        ConfirmationService,
        KfAuthGuardService,
        SpinnerVisibilityService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        CookieService,
        MessageService

    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private translate: TranslateService) {
        translate.addLangs(['en', 'de', 'es-ar', 'ja', 'pl', 'tr', 'zh']);
        translate.setDefaultLang('en');
        translate.use('en');
    }
}
