import { AfterViewInit, Component } from '@angular/core';
import { KfStorageService } from '@kf-products-core/kfhub_lib';
@Component({
    selector: 'kf-hubint-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements AfterViewInit {
    constructor(
        private storageService: KfStorageService,
    ) {
        // temporary fix. Need to do this on kfhub_lib
        this.storageService.setItem('auth_and_redirect_url', 'dashboard')
    }

    ngAfterViewInit() {

    }
}
