import * as _ from "lodash";
import { Observable } from "rxjs";

import { Injectable } from "@angular/core";
import {
    environment,
    KfAuthService,
    KfSharedConstantsService,
} from "@kf-products-core/kfhub_lib";

import { MessageService } from "./message.service";
interface Role {
    roleName: string;
}
interface Permission {
    roles: Role[];
}
interface Type {
    names: Permission[];
}
@Injectable()
export class UserPermissionsService {
    private payhub = "hrms/payhub";
    public config = environment().validationApp;
    private baseUrl = environment().baseApiUrl;
    constructor(
        private authService: KfAuthService,
        private constService: KfSharedConstantsService,
        private messageService: MessageService
    ) {}

    public get apiUrl() {
        return this.constService.getBaseApiUrl() + "/v1/hrms/";
    }
    public get apiAdminUrl() {
        return this.constService.getBaseApiUrl() + "/v2admin/adminuser/";
    }
    public getHTTPData(path: string, mock: boolean) {
        // 'assets/data/mock-countries.json'
        let url;
        if (mock) {
            url = path;
        } else {
            url = this.apiUrl + path;
        }
        return this.authService.authHttpCall("GET", url, null, null, true);
    }
    public deleteHTTPData(path: string, mock: boolean) {
        let url;
        if (mock) {
            url = path;
        } else {
            url = this.apiUrl + path;
        }
        return this.authService.authHttpCall("DELETE", url, null, null, true);
    }
    public getExpertsList() {
        const url = this.apiUrl + this.payhub + "/references/askexperts";
        return this.authService.authHttpCall("GET", url, null, null, true);
    }
    public getUserListSuggestion(
        searchString,
        clientId,
        applicationName
    ): Observable<any> {
        const url =
            this.apiAdminUrl +
            `searchusers/?clientId=${clientId}&searchColumn=EMAIL&pageIndex=1&pageSize=20&applicationName=${applicationName}&searchString=${searchString}`;
        // const url = this.apiAdminUrl + '/searchusers/?clientId=15000&pageIndex=1&pageSize=20&applicationName=UPM&searchString=sanjay';
        // const url = 'assets/data/mock-user.json';
        return this.authService.authHttpCall("GET", url, null, null, true);
    }

    public addUser(
        expertsData,
        applicationName: String = "UPM"
    ): Observable<any> {
        const url =
            this.apiUrl +
            "usermanagement/thuser?applicationName=" +
            applicationName;
        return this.authService.authHttpCall(
            "POST",
            url,
            expertsData,
            null,
            true
        );
    }
    public removeUser(user, applicationname): Observable<any> {
        const url =
            this.apiUrl +
            `usermanagement/thuser?applicationName=${applicationname}&appUserId=${user.appUserId}`;
        return this.authService.authHttpCall("DELETE", url, null, null, true);
    }
    public addEditGroup(
        expertsData,
        groupId,
        applicationName
    ): Observable<any> {
        let url =
            this.apiUrl +
            `usermanagement/groups?applicationName=${applicationName}`;
        let method = "POST";
        if (groupId) {
            url = `${this.apiUrl}usermanagement/groups/${groupId}?applicationName=${applicationName}`;
            method = "PUT";
        }

        // return this.authService.authHttpCall(method, url, expertsData);
        return this.authService.authHttpCall(
            method,
            url,
            expertsData,
            { "Content-Type": "application/json" },
            true
        );
    }

    public userPermission(permissionName, role) {
        if (sessionStorage.getItem("userPermission")) {
            let userPermission = JSON.parse(
                sessionStorage.getItem("userPermission")
            );
            // 1. MODULE
            let userModule: any[] = [];
            let userModuleFiltered: any[] = [];
            let roleFiltered: any[] = [];
            let roleObj: any = {};
            if (
                userPermission &&
                userPermission[0].hasOwnProperty("permissionType") &&
                userPermission[0]["permissionType"] === "MODULE"
            ) {
                userModule = userPermission[0].names;

                if (userModule.length > 0) {
                    userModuleFiltered =
                        _.find(userModule, [
                            "permissionName",
                            permissionName,
                        ]) || [];
                    if (
                        userModuleFiltered &&
                        userModuleFiltered.hasOwnProperty("permissionName")
                    ) {
                        roleFiltered =
                            _.filter(
                                userModuleFiltered["roles"],
                                function (obj) {
                                    if (
                                        role === "ADD_GROUP" ||
                                        role === "EDIT_GROUP"
                                    ) {
                                        return obj.roleName === role;
                                    } else {
                                        return _.startsWith(obj.roleName, role);
                                    }
                                }
                            ) || [];
                    }

                    roleFiltered = _.reverse(roleFiltered);

                    if (roleFiltered && roleFiltered.length > 0) {
                        roleObj["permissionName"] =
                            userModuleFiltered["permissionName"];
                        roleObj["permissionDesc"] =
                            userModuleFiltered["permissionDesc"];
                        roleObj["roleName"] = roleFiltered[0]["roleName"];
                        roleObj["roleType"] = roleFiltered[0]["roleType"];
                        roleObj["roleDesc"] = roleFiltered[0]["roleDesc"];
                    }
                }
            }

            let finalObject = {};

            finalObject = roleObj;
            return finalObject;
        } else {
            return {};
        }
    }
    checkUserCountryPermission(countryCode) {
        countryCode = countryCode ? countryCode.toUpperCase() : "";
        let userCountries: any[] = [];
        let isCountryAllowed: boolean = false;
        countryCode = countryCode.toUpperCase();
        if (sessionStorage.getItem("userPermission")) {
            let userPermission = JSON.parse(
                sessionStorage.getItem("userPermission")
            );
            // 2. COUNTRIES

            if (
                userPermission &&
                userPermission[1].hasOwnProperty("permissionType") &&
                userPermission[1]["permissionType"] === "COUNTRY"
            ) {
                userCountries = userPermission[1].names;
                let country = _.find(userCountries, [
                    "permissionName",
                    countryCode,
                ]);
                if (country) {
                    isCountryAllowed = true;
                }
            }
        }
        return isCountryAllowed;
    }
    isUserGroupRoleAllowed(role: string): boolean {
        const uniqueRoles = this.getUserRolesArray();
        if (uniqueRoles.length > 0) {
            return uniqueRoles.includes(role);
        }

        return false;
    }

    getUserRolesArray(): string[] {
        const userPermission = sessionStorage.getItem("userPermission");
        if (userPermission) {
            let json: Type[] = JSON.parse(userPermission);
            const roles: string[] = [];
            const onlyRoles = json[0]; // take permissionType: "MODULE"
                onlyRoles.names.forEach((permission) => {
                    permission.roles.forEach((role) => {
                        roles.push(role.roleName);
                    });
                });
           
            let uniqueRoles = [...new Set(roles)];
            return uniqueRoles;
        } else {
            return [];
        }
    }
    isRoleAssigned(countryCode, module, role): any {
        return new Promise((resolve, reject) => {
            let rolePermission = this.userPermission(module, role);
            countryCode = countryCode ? countryCode.toUpperCase() : "";
            let isRoleEnabled: boolean = false;
            if (role === "VIEW") {
                if (_.isEmpty(rolePermission)) {
                    isRoleEnabled = false;
                } else if (
                    rolePermission &&
                    rolePermission.hasOwnProperty("roleName") &&
                    rolePermission["roleName"] === role
                ) {
                    isRoleEnabled = true;
                } else {
                    isRoleEnabled = false;
                }
            } else if (countryCode === "") {
                if (
                    !_.isEmpty(rolePermission) &&
                    rolePermission["roleName"] === role + "_GLOBAL"
                ) {
                    isRoleEnabled = true;
                } else if (
                    !_.isEmpty(rolePermission) &&
                    rolePermission["roleName"] === role + "_COUNTRY"
                ) {
                    isRoleEnabled = true;
                } else if (
                    !_.isEmpty(rolePermission) &&
                    rolePermission["roleName"] === role
                ) {
                    isRoleEnabled = true;
                } else {
                    isRoleEnabled = false;
                }
            } else {
                if (
                    !_.isEmpty(rolePermission) &&
                    rolePermission["roleName"] === role + "_GLOBAL"
                ) {
                    isRoleEnabled = true;
                } else if (
                    !_.isEmpty(rolePermission) &&
                    rolePermission["roleName"] === role + "_COUNTRY"
                ) {
                    isRoleEnabled = false;
                    if (this.checkUserCountryPermission(countryCode)) {
                        isRoleEnabled = true;
                    }
                } else {
                    isRoleEnabled = false;
                }
            }
            resolve(isRoleEnabled);
        });
    }
    isUserGroupRoleAssignedAlert(role:string): any {
        return new Promise((resolve, reject) => {

            let roles = this.getUserRolesArray();
            if (!roles.includes(role)) {
                this.messageService.notify(
                    "error",
                    "",
                    "<p>You don't have permission to perform</p><p>this operation.</p>"
                );
               
                return;
            } 
            resolve(true);
        });
    }
    isRoleAssignedAlert(countryCode, module, role): any {
        return new Promise((resolve, reject) => {
            let rolePermission = this.userPermission(module, role);
            countryCode = countryCode.toUpperCase();

            if (_.isEmpty(rolePermission)) {
                this.messageService.notify(
                    "error",
                    "",
                    "<p>You don't have permission to perform</p><p>this operation.</p>"
                );
                return;
            } else if (
                !_.isEmpty(rolePermission) &&
                !rolePermission.hasOwnProperty("roleName")
            ) {
                this.messageService.notify(
                    "error",
                    "",
                    "<p>You don't have permission to perform</p><p>this operation.</p>"
                );
                return;
            } else if (
                rolePermission &&
                rolePermission.hasOwnProperty("roleName") &&
                rolePermission["roleName"] === role + "_GLOBAL"
            ) {
                // do nothing
            } else if (
                rolePermission &&
                rolePermission.hasOwnProperty("roleName") &&
                rolePermission["roleName"] === role + "_COUNTRY"
            ) {
                if (!this.checkUserCountryPermission(countryCode)) {
                    this.messageService.notify(
                        "error",
                        "",
                        "<p>You don't have permission to perform</p><p>this operation.</p>"
                    );
                    return;
                }
            }
            resolve(true);
        });
    }
}
