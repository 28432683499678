import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import {
    environment,
    KfAuthService,
    KfSharedConstantsService,
    KfIsearchResultsDataService,
    KfFilterMetadata,
    KfIpagedSearchResults,
} from '@kf-products-core/kfhub_lib';


export interface ClientDetails {
    apiToken?: string;
    surveyID?: string;
    dataCenter?: string;
    pamsId?: string;
    clientDisplayName?: string;
    clientID?: string;
    projectName?: string;
    projectId?: string;
    projectYear?: string;
    surveyTypeID?: string;
    email?: string;
    fname?: string;
    lname?: string;
    checksurvey?: string;
    newproject?: string;
    newclient?: string;
    // clientName?: string;
    
    clientName?: string;
    sapClientId?: string;
    
    workdayDetails: {
        tenantURL?: string;
        tenantUserName?: string;
        tenantPassword?: string;
        ratingScale?: 'CARS' | 'BARS'
        isPublished?: boolean;
    };
}

export interface ClientData {
    clientInfo: ClientDetails;
}

export interface ClientsSearchData {
    
        vendorSurveyId: string;
        clientName: string;
        createdBy: string;
        startDate: string,
        endDatet: string,
}

@Injectable()
export class QualtricsSurveyService implements KfIsearchResultsDataService {
    private readonly clientsPrefix = '/v2admin/clients';
    private readonly actionsPrefix = '/v2admin/actions';
    
    private config = environment().validationApp;
    public environment: 'DEV' | 'TEST' | 'STAGE' | 'PROD' = this.config.environment;
    private readonly qualtricApiBase = environment().qualtricApp.baseApiURL;

    
    constructor(private authService: KfAuthService, private http: HttpClient, private constService: KfSharedConstantsService) { }

     public searchAudit(     
        searchSurveyid: string,
        searchEmail: string,
        searchClient: string,
        startDate: string='',
        endDate: string='',
        sortBy: string, 
        sortString: string,
        pageIndex: number = 0, 
        pageSize: number = 10,
    ): Observable<any> {
        const base = this.qualtricApiBase;
        const url = base + `/kf-surveys-qualtrics/getAuditLogData?noofRecords=${pageSize}&pageIndex=${pageIndex}`;
        let reportProgress = false;
        const body = {
            sortBy: "DESC", 
            sortString: "date",
        };

        console.log(endDate,'--',startDate);

        if( (searchClient !== undefined) &&  (searchClient.length > 0)){
             Object.assign(body, {
                  clientName: searchClient
                })
        }
        if( (searchEmail !== undefined) &&  (searchEmail.length > 0)){
            Object.assign(body, {
                  createdBy: searchEmail
                })
        }
        if ( (searchSurveyid !== undefined) &&  (searchSurveyid.length > 0)){
           Object.assign(body, {
                  vendorSurveyId: searchSurveyid
                })
        }
        if ( (startDate !== undefined) &&  (startDate.length > 0) && (startDate !== 'Invalid date')){
           Object.assign(body, {
                  startDate: startDate
                })
        }
        if ( (endDate !== undefined) &&  (endDate.length > 0) && (endDate !== 'Invalid date') ){
           Object.assign(body, {
                  endDate: endDate
                })
        }

        const bodyParms= JSON.stringify(body);

        const headers = new HttpHeaders(
            { authToken: this.authService.AuthToken , 
             'Content-Type': 'application/json',
             }
        ); 
        

        const options = {
            reportProgress,
            headers,
        };

        const req = new HttpRequest('POST', url, bodyParms, options);
        return this.http.request(req);
    }

    public fetchexcludeQuestiondata( 
        noofRecords: number,
        pageIndex: number,
        sortBy: string,
        data
    ): Observable<any> {
        const base = this.qualtricApiBase;
        const url = base + `/kf-surveys-qualtrics/getSurveyQuestions?noofRecords=${noofRecords}&pageIndex=${pageIndex}&sortBy=${sortBy}`;
        let reportProgress = false;

        const headers = new HttpHeaders(
            { authToken: this.authService.AuthToken , 
             'Content-Type': 'application/json',
             }
        );         

        const options = {
            reportProgress,
            headers,
        };

        const req = new HttpRequest('POST', url, data, options);
        return this.http.request(req);
    }

    public fetchdemograhicsdata( 
        noofRecords: number,
        pageIndex: number,
        sortBy: string,
        data
    ): Observable<any> {
        const base = this.qualtricApiBase;
        const url = base + `/kf-surveys-qualtrics/getDemographics?noofRecords=${noofRecords}&pageIndex=${pageIndex}&sortBy=${sortBy}`;
        let reportProgress = false;

        const headers = new HttpHeaders(
            { authToken: this.authService.AuthToken , 
             'Content-Type': 'application/json',
             }
        );         

        const options = {
            reportProgress,
            headers,
        };

        const req = new HttpRequest('POST', url, data, options);
        return this.http.request(req);
    }

    public search(
        searchString: string, appliedFilters: KfFilterMetadata[],
        sorting: any[], pageIndex: number, pageSize: number = 20,
    ): Observable<KfIpagedSearchResults & { data: ClientsSearchData }> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.clientsPrefix + '/searchclient?';
        const paramsData = {
            pageIndex: pageIndex.toString(),
            pageSize: pageSize.toString(),
            searchString,
            clientType: 'WORKDAY_CLIENT',
            searchColumn: 'NAME',
            sortBy: 'NAME',
        };
        if (searchString == null || searchString.trim() === '') {
            delete paramsData.searchString;
        }
        const params = new HttpParams({ fromObject: paramsData });
        return this.authService.authHttpCall('get', url + params.toString());
    }

    public getClientInfo(id: number): Observable<ClientData> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.clientsPrefix + `/${id}`;
        return this.authService.authHttpCall('get', url);
    }

    public excludeQuestions(data): Observable<any> {
        const base = this.qualtricApiBase;
        const url = base + `/kf-surveys-qualtrics/excludeQuestions`;

       const headers = new HttpHeaders(
            { authToken: this.authService.AuthToken },
        );

        const options = {
            headers,
        };

        const req = new HttpRequest('POST', url, data, options);
        return this.http.request(req);
    }

    public excludeDmgQuestions(data): Observable<any> {
        const base = this.qualtricApiBase;
        const url = base + `/kf-surveys-qualtrics/excludeDemographics`;
        const headers = new HttpHeaders(
            { authToken: this.authService.AuthToken },
        );

        const options = {
            headers,
        };

        const req = new HttpRequest('POST', url, data, options);
        return this.http.request(req);
    }

    //Qualtrics start
    public createQualtricsSurveyRequest(details: ClientDetails, reportProgress = false): Observable<any> {
        const base = this.qualtricApiBase;
        const url = base + "/kf-surveys-qualtrics/trigger/formdata";
        const formData = new FormData();
       
        
        formData.append('apiKey', details.apiToken);
        formData.append('clientDisplayName', details.clientDisplayName);
        formData.append('clientName', details.clientName);
        formData.append('emailId', details.email);
        if(details.projectId){
            formData.append('projectId', details.projectId);
        }
        if(details.clientID){
            formData.append('clientId', details.clientID);
        }        
        formData.append('firstName', details.fname);
        formData.append('lastName', details.lname);
        formData.append('pamsId', details.pamsId);        
        formData.append('projectName', details.projectName);
        formData.append('projectYear', details.projectYear);
        formData.append('surveyTypeId', details.surveyTypeID);
        formData.append('vendorSurveyId', details.surveyID);
        formData.append('dateCenterId', details.dataCenter);

        formData.append('existingSurvey', details.checksurvey);
        formData.append('createProject', details.newproject);
        formData.append('createClient', details.newclient);        

        const headers = new HttpHeaders(
            { authToken: this.authService.AuthToken },
        );

        const options = {
            reportProgress,
            headers,
        };

        const req = new HttpRequest('POST', url, formData, options);
        return this.http.request(req);
    }

    public getQualtricsClientInfo(): Observable<any> {
        
        const base = this.qualtricApiBase;
        const url = base + "/kf-surveys-qualtrics/getreferencedata";
        const req = new HttpRequest('GET', url);
        return this.http.request(req);
    }

    public checkSurveyInfo(a): Observable<any> {        
        const base = this.qualtricApiBase;
        const url = base + `/kf-surveys-qualtrics/checkSurvey?VendorSurveyID=${a}`;
        const req = new HttpRequest('GET', url);
        return this.http.request(req);
    }
    
    public checkClientInfo(clientName): Observable<any> {        
        const base = this.qualtricApiBase;
        const url = base + `/kf-surveys-qualtrics/checkClient?clientName=${clientName}`;
        const req = new HttpRequest('GET', url);
        return this.http.request(req);
    }
}
