import { Injectable } from '@angular/core';
import {
    KfAuthService,
    KfSharedConstantsService,
    KfFilterMetadata,
    KfIpagedSearchResults,
    KfIsearchResultsDataService,
} from '@kf-products-core/kfhub_lib';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
import { FilterMetadataConverterService } from './filter-metadata-converter.service';

export interface UsersSearchdata {
    users: {
        [idx: string]: any;
    }[];
}

@Injectable()
export class UsersService implements KfIsearchResultsDataService {
    private readonly usersPrefix = '/v2admin/adminuser/';
    private readonly clientId = 14193;

    constructor(
        private authService: KfAuthService,
        private constService: KfSharedConstantsService,
        private filterMetadataConverter: FilterMetadataConverterService,
    ) { }

    public search(
        searchString: string, appliedFilters: KfFilterMetadata[],
        sorting: any[], pageIndex: number, pageSize: number = 20,
    ): Observable<KfIpagedSearchResults & { data: UsersSearchdata }> {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.usersPrefix + 'searchusers';
        const paramsData = {
            clientId: _.toString(this.clientId),
            pageIndex: pageIndex.toString(),
            pageSize: pageSize.toString(),
            searchString,
            searchColumn: 'NAME',
            sortBy: 'NAME',
        };
        if (searchString == null || searchString.trim() === '') {
            delete paramsData.searchString;
        }
        if (appliedFilters) {
            const additionalParams = this.filterMetadataConverter.toQueryParamsObject(appliedFilters);
            _.assign(paramsData, additionalParams);
        }
        const params = new HttpParams({ fromObject: paramsData });
        return this.authService.authHttpCall('get', url + '?' + params.toString());
    }

    public getById(id: number) {
        const base = this.constService.getBaseApiUrl();
        const url = base + this.usersPrefix + _.toString(id);
        const params = new HttpParams({
            fromObject: {
                clientId: _.toString(this.clientId),
            },
        });
        return this.authService.authHttpCall('get', url + '?' + params.toString());
    }
}
