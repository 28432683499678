import { Injectable } from '@angular/core';
import { FileUploadService } from './file-upload.service';
import {
    KfSharedConstantsService,
} from '@kf-products-core/kfhub_lib';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class ReportDataUploaderService {
    private readonly prefix = '/v1/objects/payanalyticsimage';

    constructor(private fileUploadService: FileUploadService) { }

    upload(clientId: number, countryId: number, reportType: string, objectType: string, file: File) {
        const paramsData = {
            clientId: clientId.toString(),
            countryId: countryId.toString(),
            reportType,
            objectType,
        };
        const params = new HttpParams({ fromObject: paramsData });
        const url = this.prefix + '?' + params.toString();
        return this.fileUploadService.upload(url, file);
    }
}
